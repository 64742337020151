import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "well-projects";

export const getWellProjects = (tenantKey: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, AuthHeader)
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getCampaignBasedWellProjects = (tenantKey: any, filter: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: { filter: filter },
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getWellProjectCode = (tenantKey: any, code: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						well_project_code: { $regex: code, $options: "i" },
					},
				}
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addWellProjectItem = (tenantKey: any, data: any, user: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						project_code: data.project_code,
						well_project_name: data.well_project_name,
					},
				}
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							well_project_name: "",
						},
					});
				} else {
					axios
						.post(
							`${url}/${tenantKey}/${apiURL}/add`,
							{ data, user },
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updateWellProjectItem = (
	tenantKey: any,
	id: any,
	data: any,
	initialData: any,
	user: any,
	formList: any = null
) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						_id: { $ne: id },
						project_code: data.project_code,
						well_project_name: data.well_project_name,
					},
				}
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							well_project_name: "",
						},
					});
				} else {
					axios
						.put(
							`${url}/${tenantKey}/${apiURL}/update`,
							{
								filter: { _id: id },
								data: data,
								initialData,
								user,
								formList,
								options: { upsert: true, new: true },
							},
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const deleteWellProjectItem = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		console.log("deleting well project", tenantKey, id, data);
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/delete`,
				{
					filter: { _id: id },
					data,
					user,
				},
				AuthHeader
			)
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				console.log(e);
				reject(e?.response?.data?.error);
			});
	});
};
