import axios from "axios";
import { url, AuthHeader, superAdminRole } from "../../utils/_exports";
const apiURL = "well-programme-template";

export const getWellProgrammeTemplateList = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addWellProgrammeTemplate = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateWellProgrammeTemplate = (
	filter: any,
	data: any,
	options: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${apiURL}`,
				{
					filter: filter,
					data: data,
					options: options,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				console.log(e);
				reject(e);
			});
	});
};

type LevelType = 'phase' | 'task' | 'deliverable';
type ProcessType = 'add' | 'update' | 'delete';
export const findAndUpdateWellProgrammeTemplate = (
	filter: any,
	data: any,
	options: any,
	level: LevelType,
	processType: ProcessType
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${apiURL}/findUpdate`,
				{
					filter: filter,
					data: data,
					options: options,
					level: level,
					processType: processType,
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				console.log(e);
				reject(e);
			});
	});
};

export const deleteWellProgrammeTemplate = (id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${apiURL}`, {
				params: {
					filter: {
						_id: id,
					},
				},
				...AuthHeader,
			})
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
