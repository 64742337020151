import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Breadcrumb, Button, Flex, Tooltip, Typography } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router";
import Emitter from "../utils/emitter";
import { HomeContext } from "../containers/Home";
import { initialExtraMenu, initialMainMenu } from "../services/MainMenu";
import { GetAntIcon } from "../utils/ant_icons";
import useHandleContextMenu from "../hooks/useHandleContextMenu";

function BreadcrumbComponent(props: any) {
	const context: any = useContext(HomeContext);
	const location: any = useLocation();
	const navigate = useNavigate();

	const [menuRoutes, setMenuRoutes]: any = useState<any>(null);
	const [breadCrumb, setBreadCrumb]: any = useState<any>([]);

	const { handleContextMenu } = useHandleContextMenu();

	useEffect(() => {
		// Recursive function to flatten menu items and filter out ones with the "to" prop
		const flattenMenuItemsWithToProp = (items: any) => {
			return items.flatMap((item: any) => {
				const menuWithTo = item.to ? [{ ...item, to: item.to }] : [];
				const menuChildren = item.children
					? flattenMenuItemsWithToProp(item.children)
					: [];
				const specialChildren = item.child
					? flattenMenuItemsWithToProp(item.child)
					: [];
				return [...menuWithTo, ...menuChildren, ...specialChildren];
			});
		};

		const flatMenuItemsWithToProp = flattenMenuItemsWithToProp([
			...initialMainMenu,
			...initialExtraMenu,
		]);
		setMenuRoutes(flatMenuItemsWithToProp);
	}, [context]);

	const getBreadcrumbNameFromPath = (path: any, solo: any, index: any) => {
		const keyPathToText = (keyName: any) => {
			if (keyName) return keyName;
			// .split("-")
			// .map((part: any) => part.charAt(0).toUpperCase() + part.slice(1))
			// .join(" ");
			else return "undefined";
		};

		let splittedPath = path.split("/");
		if (
			splittedPath[1] === "projects" &&
			solo != "add-new-project" &&
			solo != "well-settings" &&
			solo != "project-settings"
		) {
			if (index == 1) {
				solo = context.currentProject?.project_name;
			}
			if (index == 2) {
				solo = context.currentWell?.well_project_name;
			}
			if (index == 3) {
				solo = context.currentPhase?.phase_name;
			}
			if (index == 4) {
				solo = context.currentTask?.task_name;
			}
		} else {
			solo = keyPathToText(solo);
		}

		const route = menuRoutes.find((route: any) => route?.to?.includes(solo));
		return route ? route.propTitle : keyPathToText(solo);
	};

	const findChildRoute = (parentPath: any, currentPath: any) => {
		const parentRoute = menuRoutes.find(
			(route: any) => route.path === parentPath
		);
		if (!parentRoute || !parentRoute.children) return null;
		return parentRoute.children.find((childRoute: any) =>
			currentPath.startsWith(parentPath + childRoute.path)
		);
	};

	useEffect(() => {
		if (menuRoutes) {
			const pathSnippets = location.pathname.split("/").filter((i: any) => i);
			const breadcrumbItems = [
				{
					key: "home",
					title: "Home",
					href: "/dashboard",
					index: 1,
				},
			];
			const breadcrumbItemsAdd = pathSnippets.map(
				(slicedPath: any, index: any) => {
					const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
					const breadcrumbName = getBreadcrumbNameFromPath(
						url,
						slicedPath,
						index
					);
					if (breadcrumbName) {
						return {
							key: breadcrumbName + index,
							title: breadcrumbName,
							href: url,
							index: index + 2,
						};
					}
					return null;
				}
			);

			setBreadCrumb([...breadcrumbItems, ...breadcrumbItemsAdd]);
		}
	}, [location.pathname, menuRoutes]);

	const handleBreadCrumbNavigate = async (
		breadcrumb: any,
		position: any = null
	) => {
		try {
			await context?.handlePageChange();
			sessionStorage.removeItem("main-tabkey");
			sessionStorage.removeItem("admin-tabkey");
			sessionStorage.removeItem("tabkey");
			navigate(breadcrumb.href);
		} catch {}
	};

	const itemRender = (
		currentRoute: any,
		params: any,
		items: any,
		paths: any
	) => {
		const isLast = currentRoute?.index === items.length;

		// Convert the currentRoute title to a shorter version if it is too long
		let displayText = currentRoute.title;
		if (currentRoute.title.length > 20) {
			displayText = currentRoute.title.slice(0, 20) + "...";
		}

		return isLast ? (
			<span
				style={{
					borderRadius: "3px",
					background: "rgba(255,255,255,0.2)",
					padding: "0px 5px",
				}}
				onContextMenu={(event: React.MouseEvent<HTMLButtonElement>) =>
					handleContextMenu(event, { link: window.location.href })
				}
			>
				{displayText}
			</span>
		) : (
			// a tag was changed to span causing the highlight to disappear
			<a
				onClick={() => {
					handleBreadCrumbNavigate(currentRoute);
				}}
				onContextMenu={(event: any) => {
					const location = window.location.origin + currentRoute.href;
					handleContextMenu(event, { link: location });
				}}
			>
				{displayText}
			</a>
		);
	};

	return (
		<>
			<Breadcrumb
				key={"Breadcrumb"}
				items={breadCrumb}
				itemRender={itemRender}
			/>
		</>
	);
}

export default BreadcrumbComponent;
