import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "deliverables";

export const getDeliverables = (tenantKey: any, task_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				params: {
					filter: {
						task_id,
					},
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getDeliverablesGeneral = (tenantKey: any, task_ids: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/get-task-deliverables`,
				{
					filter: {
						task_id: { $in: task_ids },
					},
				},
				{
					...AuthHeader,
				}
			)
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addDeliverableItem = (tenantKey: any, data: any, user: any) => {
	data.status = "New";
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						deliverable_name: data.deliverable_name,
						task_id: data.task_id,
					},
				},
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							deliverable_name: "",
						},
					});
				} else {
					axios
						.post(
							`${url}/${tenantKey}/${apiURL}/add`,
							{ data, user },
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			});
	});
};

export const updateDeliverableItem = (
	tenantKey: any,
	id: any,
	data: any,
	initialData: any,
	user: any,
	formList: any = null
) => {
	console.log(tenantKey, id, data);
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						_id: { $ne: id },
						deliverable_name: data.deliverable_name || "",
						task_id: data.task_id || "",
					},
				},
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							deliverable_name: "",
						},
					});
				} else {
					axios
						.put(
							`${url}/${tenantKey}/${apiURL}/update`,
							{
								filter: { _id: id },
								data,
								initialData,
								user,
								formList,
								options: { upsert: true, new: true },
							},
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			});
	});
};

export const deleteDeliverableItem = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/delete`,
				{
					filter: {
						_id: id,
					},
					data,
					user,
				},
				AuthHeader
			)
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const updateDeliverableRoles = (tenantKey: any, role_id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/update-roles`,
				{ role_id: role_id },
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateDeliverableDisabled = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}/disable`,
				{
					filter: { _id: id },
					data,
					user,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};
