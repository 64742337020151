import React, { useEffect, useRef, useState } from "react";
import { GetAntIcon } from "../utils/ant_icons";
import "../assets/css/contextMenu.css";
import Emitter from "../utils/emitter";

function ContextMenu(props: any) {
	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const contextMenuRef = useRef<HTMLDivElement>(null);

	const info = props.info;

	useEffect(() => {
		setContextMenu(props.position);
		console.log("Context menu opened at: ", props.position);
	}, [props.position]);

	useEffect(() => {
		if (contextMenu) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [contextMenu]);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			contextMenuRef.current &&
			!contextMenuRef.current.contains(event.target as Node)
		) {
			handleClose();
		}
	};

	const handleCopyLink = (linkToCopy: any) => {
		// const linkToCopy = `${window.location.origin}/some-link`;
		navigator.clipboard
			.writeText(linkToCopy)
			.then(() => {
				Emitter.emit("alert", {
					type: "success",
					message: "Link copied to clipboard!",
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.catch((err) => {
				console.error("Failed to copy the link: ", err);
			});
		handleClose();
	};

	const handleClose = () => {
		setContextMenu(null);
	};

	const contextMenuItems = [
		{
			key: 1,
			title: "Copy link",
			icon: GetAntIcon("link"),
			action: () => handleCopyLink(info.link),
		},
	];

	return (
		<>
			{contextMenu && (
				<div
					ref={contextMenuRef}
					className="context-menu"
					style={{
						top: contextMenu.mouseY,
						left: contextMenu.mouseX,
					}}
					onClick={handleClose}
				>
					{contextMenuItems.map((item) => {
						return (
							<a
								key={item.key}
								onClick={item.action}
								style={{ display: "flex", flexWrap: "nowrap", gap: "12px" }}
							>
								<span style={{ width: 16 }}>{item.icon}</span>
								<span>{item.title}</span>
							</a>
						);
					})}
				</div>
			)}
		</>
	);
}

export default ContextMenu;
