import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Popconfirm, Upload } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AvatarEditor from "react-avatar-editor";
import { GetAntIcon } from "../utils/ant_icons";
import { RcFile } from "antd/es/upload";
import Emitter from "../utils/emitter";
import { capitalizeFirstLetter, formatSentence } from "../utils/utils";

function UploadComponent(props: any) {
	const imageEditorRef: any = useRef(null);
	const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
	const [imageUpdated, setImageUpdated] = useState<boolean>(false);
	const [imageState, setImageState] = useState<any>(null);

	const [isImageHovered, setImageHovered] = useState<boolean>(false);

	const label = props?.label;
	const prefix = props?.prefix;
	const initials = props?.initials;
	const setEmptyModalOpen = props?.setEmptyModalOpen;
	const image = props?.image;
	const setImage = props?.setImage;
	const handleImageSave = props?.handleImageSave;
	const handleImageRemove = props?.handleImageRemove;
	const removeMessage = props?.removeMessage;

	const beforeUpload = (file: RcFile) => {
		setImageHovered(false);
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			Emitter.emit("alert", {
				type: "error",
				message: `Failed to upload. ${capitalizeFirstLetter(
					label
				)} should be less than 5MB in JPEG or PNG.`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}
		const isLt2M = file.size / 1024 / 1024 < 5;
		if (!isLt2M) {
			Emitter.emit("alert", {
				type: "error",
				message: `Failed to upload. ${capitalizeFirstLetter(
					label
				)} should be less than 5MB in JPEG or PNG.`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		}

		return isJpgOrPng && isLt2M;
	};

	const handleChange = (info: any) => {
		// console.log(info);
		if (info.file.status === "uploading") {
			return;
		}
		if (info.file.status === "done") {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj as RcFile, (url) => {
				// handleFormChange("profile_picture", url);
				// console.log(url);
				setImageUpdated(true);
				setImageState(url);
			});
		}
	};

	const getBase64 = (img: RcFile, callback: (url: string) => void) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result as string));
		reader.readAsDataURL(img);
	};

	return (
		<Upload
			// ref={uploadRef}
			name="avatar"
			listType="picture-circle"
			className="avatar-uploader"
			openFileDialogOnClick={false}
			showUploadList={false}
		>
			<div
				id="profile-picture-edit"
				className="profile-picture-edit"
				onMouseEnter={() => setImageHovered(true)}
				onMouseLeave={() => setImageHovered(false)}
				onClick={() => {
					setImageModalOpen(true);
					setImageState(image);
				}}
			>
				{image ? (
					<img
						src={image}
						alt="avatar"
						style={{ width: "100%", objectFit: "contain" }}
					/>
				) : (
					<div className="profile-picture-initials">
						<span className="profile-initials">{initials}</span>
					</div>
				)}
				{isImageHovered ? (
					<div className="profile-picture-hover">
						{image ? (
							<span className="elemental-text-color">Update {label}</span>
						) : (
							<span className="elemental-text-color">Upload {label}</span>
						)}
					</div>
				) : (
					<></>
				)}
			</div>
			<Modal
				destroyOnClose
				closable={false}
				open={imageModalOpen}
				title={
					<div className="modal-generic-header">
						Edit {prefix} {label}
					</div>
				}
				footer={
					<div className="modal-generic-footer">
						{image ? (
							<Popconfirm
								style={{ marginRight: "auto", background: "#393939" }}
								overlayInnerStyle={{
									background: "#393939",
									maxWidth: "300px",
									padding: "16px",
								}}
								placement="topLeft"
								okText={`Remove ${label}`}
								okType="danger"
								title=""
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								cancelText={<span style={{ color: "white" }}>Cancel</span>}
								cancelButtonProps={{ ghost: true }}
								overlayClassName="popconfirm-danger"
								description={removeMessage}
								onOpenChange={(open: any) => {
									if (!open) {
										setEmptyModalOpen(false);
									}
								}}
								onConfirm={() => {
									setEmptyModalOpen(false);
									handleImageRemove();
									setImageModalOpen(false);
									setImageUpdated(false);
									setImageState(null);
									setImage(null);
								}}
								onCancel={() => {
									setEmptyModalOpen(false);
								}}
							>
								<Button
									className="ant-btn-secondary"
									danger
									style={{ marginRight: "auto" }}
									onClick={() => {
										setEmptyModalOpen(true);
									}}
								>
									Remove {label}
								</Button>
							</Popconfirm>
						) : (
							<Button
								className="ant-btn-secondary"
								style={{ marginRight: "auto" }}
								disabled
							>
								Remove {label}
							</Button>
						)}
						<Button
							className="ant-btn-secondary"
							onClick={() => {
								setImageModalOpen(false);
								setImageUpdated(false);
								setImageState(null);
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={!imageUpdated}
							type={"primary"}
							onClick={() => {
								setImageModalOpen(false);
								setImageUpdated(false);
								setImageState(null);
								handleImageSave(
									imageEditorRef.current
										.getImageScaledToCanvas()
										.toDataURL("image/jpeg")
								);
							}}
						>
							Save
						</Button>
					</div>
				}
				centered
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: "20px",
					}}
				>
					{imageState && (
						<AvatarEditor
							ref={imageEditorRef}
							image={imageState}
							width={200}
							height={200}
							borderRadius={100}
							// style={{ width: "100%", objectFit: "contain" }}
						/>
					)}
					<Upload
						customRequest={({ file, onSuccess }: any) => {
							setTimeout(() => {
								setImageHovered(false);
								onSuccess("ok");
							}, 0);
						}}
						beforeUpload={beforeUpload}
						onChange={handleChange}
						maxCount={1}
						showUploadList={false}
					>
						<Button icon={GetAntIcon("upload")}>Upload a new {label}</Button>
					</Upload>
				</div>
			</Modal>
		</Upload>
	);
}

export default UploadComponent;
