import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "audit-logs";

export const getAuditLogList = (
	tenantKey: any,
	identifierData: any,
	type: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}/${type}`, {
				params: identifierData,
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
