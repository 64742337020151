import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Popconfirm, Tooltip } from "antd";
import CustomForm from "../CustomForm";
import Table, { ColumnsType } from "antd/es/table";
import { HomeContext } from "../../containers/Home";
import { useForm } from "antd/es/form/Form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "../FormButtonSave";
import Emitter from "../../utils/emitter";
import useUpdateDeliverableStatus from "../../hooks/useUpdateDeliverableStatus";
import { getCrudaClass } from "../../utils/lookup_list";

function DeliverableConfigurationModal(props: any) {
	const data: any = props.data;
	const context: any = useContext(HomeContext);
	const [formRef] = useForm();

	const [formReset, setFormReset] = useState<boolean>(true);
	const [updated, setUpdated] = useState<boolean>(false);
	const [initialForm, setInitialForm]: any = useState<any>(null);

	const [modalVisible, setModalVisible] = useState(false);

	const [cancelPopover, setCancelPopover] = useState<any>(false);
	const [deletePopover, setDeletePopover] = useState<any>(false);

	const open = props.open;
	const setOpen = props.setOpen;
	const taskID = props.taskID;
	const taskRecord = props?.taskRecord;
	const deliverableRecord = props.deliverableRecord;
	const activeRowKey = props.activeRowKey;
	const handleModalClose = props.handleModalClose;
	const handleFormUpdate = props.handleFormUpdate;

	const extraData = props.extraData;

	const { updateTaskStatus } = useUpdateDeliverableStatus(
		context?.currentTenantKey,
		taskID,
		context?.currentProject.project_code,
		context?.currentWell.well_project_code,
		props?.user
	);

	useEffect(() => {
		Emitter.emit("loading", true);

		resetForm(deliverableRecord);

		Emitter.emit("loading", false);
	}, [updated, context.currentTenantKey, props?.deliverableRecord]);

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			setInitialForm(values);
			context?.handleFormUpdating(false, activeRowKey || "deliverable_new");
		}
	};

	const handleFormCancel = () => {
		setOpen(false);
		setCancelPopover(false);
		handleModalClose();
		resetForm();
	};

	const handleModalOk = (values: any) => {
		setModalVisible(false);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
		handleModalClose();
	};

	const handleFormSave = (values: any, setForm: any = true) => {
		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});

		getCrudaClass("deliverable").then((crudaClassInstance: any) => {
			if (activeRowKey) {
				//Set undefined to empty string
				Object.keys(values).forEach((element: any) => {
					if (values[element] == undefined) {
						if (element == "subsidiaries") {
							values[element] = [];
						} else {
							values[element] = null;
						}
					}
				});

				const form = crudaClassInstance.getFormList("deliverable");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				crudaClassInstance
					.updateLookupItem(
						"deliverable",
						props?.option,
						activeRowKey,
						context?.currentTenantKey,
						values,
						initialForm,
						{
							templateData: props?.otherData,
							project_code: context?.currentProject?.project_code,
							well_project_code: context?.currentWell?.well_project_code,
							task_id: taskID,
							phase_id: props?.phaseID,
							taskRecord,
						},
						props.user,
						formList
					)
					.then((updatedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Deliverable has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, activeRowKey);
						// setActiveRecord(updatedItem);
						if (setForm) {
							resetForm(values, true);
							handleFormUpdate(updatedItem);
						} else {
							resetForm(null, true);
							handleFormUpdate(null);
							handleModalClose();
						}
						setUpdated(!updated);
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This deliverable already exists, please ensure the deliverable ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					})
					.finally(() => {
						// if (props?.option != "template") updateTaskStatus();
					});
			} else {
				crudaClassInstance
					.addLookupItem(
						"deliverable",
						props?.option,
						values,
						{
							templateData: props?.otherData,
							project_code: context?.currentProject?.project_code,
							well_project_code: context?.currentWell?.well_project_code,
							task_id: taskID,
							phase_id: props?.phaseID,
							taskRecord,
						},
						context?.currentTenantKey,
						null,
						props.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Deliverable has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "deliverable_new");

						if (setForm) {
							resetForm(values, true);
							handleFormUpdate(addedItem);
						} else {
							resetForm(null, true);
							handleFormUpdate(null);
							handleModalClose();
						}
						setUpdated(!updated);
					})
					.catch((error: any) => {
						console.log(error);
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This deliverable already exists, please ensure the deliverable ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					})
					.finally(() => {
						// if (props?.option != "template") updateTaskStatus();
						Emitter.emit("loading", false);
					});
			}
		});
	};

	const handleFormDelete = () => {
		context?.handleFormUpdating(false, activeRowKey);
		getCrudaClass("deliverable").then((crudaClassInstance: any) => {
			crudaClassInstance
				.deleteLookupItem(
					"deliverable",
					props?.option,
					activeRowKey,
					context?.currentTenantKey,
					null,
					{
						templateData: props?.otherData,
						project_code: context?.currentProject?.project_code,
						well_project_code: context?.currentWell?.well_project_code,
						task_id: taskID,
						phase_id: props?.phaseID,
					},
					props.user
				)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Deliverable has been successfully deleted.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					setDeletePopover(false);
					setTimeout(() => {
						handleModalClose();
						handleFormUpdate();
					}, 100);
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.finally(() => {
					if (props?.option != "template") updateTaskStatus();
					// setUpdated(!updated);
					// setSideContainerOpen(false);
					// setActiveRowKey(null);
					// setActiveRecord(null);
					// setFilteredExtraData(null);
				});
		});
	};

	return (
		<Modal
			closable={false}
			width={"80%"}
			title={
				taskRecord?.closed === true ? (
					<span style={{ display: "flex", gap: "5px", fontSize: "14px" }}>
						<div>Deliverable Configuration</div>
						<div style={{ color: "rgba(255, 255, 255, 0.45)" }}>
							Further edits on this deliverable are not allowed as task is
							closed.
						</div>
					</span>
				) : (
					"Deliverable Configuration"
				)
			}
			open={open}
			classNames={{ header: "generic-header", footer: "generic-footer" }}
			centered
			destroyOnClose
			footer={() => {
				return (
					<div
						className="generic-footer"
						style={{ height: "auto", padding: "0", width: "100%" }}
					>
						{/* {taskRecord?.closed !== true && ( */}
						<Popconfirm
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							placement="topLeft"
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										Permanently delete this deliverable?
										<br />
										This action cannot be undone.
									</>
								</div>
							}
							open={deletePopover}
							//description="Permanently delete this item? This action cannot be undone."
							onConfirm={handleFormDelete}
							overlayClassName="popconfirm-danger"
							onCancel={() => {
								// Handle cancel action if needed
								setDeletePopover(false);
							}}
							okText="Delete"
							okType="danger"
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							cancelText={<span>Cancel</span>}
							cancelButtonProps={{ ghost: true }}
						>
							<Tooltip
								title={
									taskRecord?.closed === true
										? "This deliverable is not allowed to be deleted as it is closed."
										: ""
								}
							>
								<Button
									disabled={
										!activeRowKey ||
										deliverableRecord?.disabled ||
										taskRecord?.closed === true
									}
									className="ant-btn-secondary"
									style={{ marginRight: "auto" }}
									danger
									onClick={() => {
										setDeletePopover(true);
									}}
								>
									Delete
								</Button>
							</Tooltip>
						</Popconfirm>
						{/* )} */}

						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							open={cancelPopover}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={() => {
								setCancelPopover(false);
								// handleFormCancel();
								setTimeout(() => {
									handleFormCancel();
								}, 300);
							}}
							okText="Continue editing"
							okType="default"
							onConfirm={() => {
								setCancelPopover(false);
							}}
						>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									} else {
										setCancelPopover(true);
									}
								}}
							>
								{/* {taskRecord?.status !== "Closed" ? <>Cancel</> : <>Close</>} */}
								Cancel
							</Button>
						</Popconfirm>

						{/* {taskRecord?.closed !== true && ( */}
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										This will update tag in all groups. Do you wish to proceed?
									</>
								</div>
							}
							placement="topRight"
							open={modalVisible}
							cancelButtonProps={{ ghost: true }}
							cancelText="Cancel"
							onCancel={handleModalCancel}
							onConfirm={handleModalOk}
							okText="Proceed"
							okType="default"
						>
							<FormButtonSave
								activeKey={activeRowKey || "deliverable_new"}
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
								setFormReset={setFormReset}
								buttonType={"dropdown-save"}
								labelText="Save and close"
								saveFunction={(values: any) => {
									handleFormSave(values, false);
								}}
							></FormButtonSave>
						</Popconfirm>
						{/* )} */}
					</div>
				);
			}}
		>
			<div
				style={{
					overflow: "auto",
					height: "50vh",
					display: "flex",
					flexDirection: "row",
					gap: "30px",
					padding: "15px",
				}}
			>
				<div style={{ width: "100%", height: "100%" }}>
					<CustomForm
						setFormReset={setFormReset}
						formRef={formRef}
						dataOptions={extraData}
						tabKey={"deliverable"}
						formOption={props?.option}
						activeRowKey={activeRowKey}
						activeRecord={deliverableRecord}
						disabledKey={"disabled"}
						disabledTooltipMessage={
							taskRecord?.closed === true ? (
								<span>
									Further edits on this task are not allowed as it is closed.
								</span>
							) : (
								<span>
									Further edits on this deliverable are not allowed as it is
									excluded.
								</span>
							)
						}
						specialDisabledTooltipMessage={
							taskRecord?.closed === true ? (
								<span>
									Further edits on this task are not allowed as it is closed.
								</span>
							) : (
								<span>
									Further edits on this deliverable are not allowed as it is
									excluded automatically due to excluded task.
									<br />
									<br />
									To include this deliverable, please include the task first.
								</span>
							)
						}
						readOnly={taskRecord?.closed === true}
					/>
				</div>
			</div>
		</Modal>
	);
}

export default DeliverableConfigurationModal;
