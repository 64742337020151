import React, { useEffect } from "react";
import { getToken } from "./api-server/usertoken";
import Emitter from "../utils/emitter";

const LoginSuccess = (props: any) => {
	const redirect = () => {
		if (localStorage.getItem("redirectURL")) {
			let url: any = localStorage.getItem("redirectURL") || "";
			localStorage.removeItem("redirectURL");
			let split = url.split("/");
			if (split[1] === process.env.REACT_APP_CLIENT_TENANT_ID) {
				window.location.href = url;
			} else {
				window.location.href = `/dashboard`;
			}
		} else {
			window.location.href = `/dashboard`;
		}
	};

	useEffect(() => {
		if (
			localStorage.getItem(`idToken`) &&
			localStorage.getItem(`idToken`) !== undefined
		) {
			redirect();
		}
		if (!localStorage.getItem(`idToken`)) {
			getToken()
				.then(async (res: any) => {
					redirect();
					// await this.props.dispatch({
					// 	type: "LOGIN_REGISTER",
					// 	payload: res.data,
					// });
					// await this.props.dispatch({ type: "LOGIN", payload: {} });
					// const sso_token = {
					// 	idToken: res.data?.idToken,
					// 	accessToken: res.data?.accessToken,
					// };
					// if (sso_token.idToken && sso_token.accessToken) {
					// 	await this.props.dispatch({
					// 		type: "SET_SSO_TOKEN",
					// 		payload: sso_token,
					// 	});
					// }
					// if (localStorage.getItem(`pbiToken`)) {
					// 	this.Redirect();
					// } else {
					// 	getPbiToken(res.data?.accessToken).then((result: any) => {
					// 		this.Redirect();
					// 	});
					// }
				})
				.catch(() => {
					// window.location.href = "/";
				});
		}
	}, []);

	return <></>;
};

export default LoginSuccess;

// const mapStateToProps = (state: any) => {
// 	return {
// 		isLoggedIn: state.isLoggedIn,
// 		userToken: state.userToken,
// 		loginRegister: state.loginRegister,
// 	};
// };

// export default connect(mapStateToProps)(LoginSuccess);
