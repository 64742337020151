import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { HomeContext } from "./Home";
import { useLocation, useNavigate } from "react-router";
import {
	Button,
	Divider,
	Empty,
	Input,
	Space,
	Switch,
	Table,
	Tooltip,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GetAntIcon } from "../utils/ant_icons";
import CheckboxDropdown from "../components/CheckboxDropdown";
import { checkPrivilege } from "../utils/utils";
import squareHehe from "../assets/image/square hehe.svg";
import { setLookupData } from "../utils/lookupFinder";
import Emitter from "../utils/emitter";
import { getCampaignBasedWellProjects } from "../services/api-server/well_projects";
import NoAccess from "./NoAccess";
import { getPhasesAndTasks } from "../services/api-server/phases";

import completedSvg from "../assets/image/completed.svg";
import blockedSvg from "../assets/image/blocked.svg";
import loadingSvg from "../assets/image/loading.svg";
import newSvg from "../assets/image/new.svg";
import elementalSvg from "../assets/image/elemental 'e'.svg";
import { getProject } from "../services/api-server/projects";
import ExpandableText from "../components/ExpandableText";
import { excludedMark, statusIcon } from "../utils/statusIcon";
import {
	getDeliverables,
	getDeliverablesGeneral,
} from "../services/api-server/deliverables";
import AuditLogModal from "../components/Modal/AuditLogModal";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
// import {
// 	getDeliverables,
// 	getDeliverablesList,
// } from "../services/api-server/deliverables";
const WellTemplateExample: any = [
	{ label: "Well project title", key: "well_project_name" },
	{ label: "Operation type", key: "operation_type" },
	{ label: "Est. duration (days)", key: "estimated_duration_days" },
	{ label: "Planned start date", key: "planned_start_date" },
	{ label: "Actual start date", key: "actual_start_date" },
	{ label: "Planned end date", key: "planned_end_date" },
];

const WellDetailsExample: any = [
	{ label: "Location", key: "location" },
	{ label: "Latitude", key: "latitude" },
	{ label: "Longitude", key: "longitude" },
	{ label: "Project template", key: "project_template" },
	{ label: "Block", key: "block" },
	{ label: "Field", key: "field" },
	{ label: "Platform", key: "platform" },
	{ label: "Water depth", key: "water_depth", suffix: "meters" },
	{ label: "License", key: "license" },
	{ label: "Purpose", key: "well_purpose" },
	{ label: "Status", key: "well_status" },
];

const tempWellList = [
	{
		well_project_name: "Well 1",
		well_project_code: "Code24A",
		planned_start_date: "Date A",
		estimated_duration_days: "30 days",
		operation_type: "Drilling",
		well_project_comments: "Some comments",
		location: "On Shore",
		latlong: ["50", "50"],
		block: "A",
		field: "C",
		platform: "Platform ABCDEF ANSHA AJS",
		water_depth: "300 meter",
		license: "License C",
		purpose: "Appraisal",
		purpose_type: "Confirmed",
		status: "Active",
		status_type: "Injecting",
		well_comments: "some well comments",
		project_template: "Template A",
		created_on: "Creation Date A",
		modified_on: "Modified Date B",
		is_removed: "false",
	},
];

const kanbanStatusExample: any = [
	"Concept",
	"Select",
	"Design",
	"Execute",
	"Evaluate",
];

function WellOverview(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [wellTitle, setWellTitle] = useState<any>(null);
	const [projectInfo, setProjectInfo] = useState<any>(null);
	const [projectTitle, setProjectTitle] = useState<any>(null);
	const [wellDetailsExpand, setWellDetailsExpand] = useState<any>(false);
	const [activePage, setActivePage] = useState<any>("kanban");
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [descMode, setDescMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<any>("");

	const { handleContextMenu } = useHandleContextMenu();

	const [projectRoleAssignmentList, setProjectRoleAssignmentList] =
		useState<any>([]);

	const [phaseList, setPhaseList] = useState<any>(null);
	const [filteredPhaseList, setFilteredPhaseList] = useState<any>(null);
	const [taskList, setTaskList] = useState<any>(null);
	const [filteredTaskList, setFilteredTaskList] = useState<any>(null);

	const [isLoading, setIsLoading] = useState<any>(true);

	const [selectedFilters, setSelectedFilters] = useState<any>({
		assigned_to: [],
		status: [],
	});

	const [hideExcluded, setHideExcluded] = useState<any>(true);

	//Audit Logs states
	const [auditOpen, setAuditOpen] = useState<any>(false);
	const [auditData, setAuditData] = useState<any>([]);

	const setWellContent = (currentWellData: any, infoKey: any) => {
		let content = "-";
		if (
			currentWellData[infoKey] ||
			infoKey == "latitude" ||
			infoKey == "longitude"
		) {
			content = setLookupData(context.allData, currentWellData, infoKey);
			if (infoKey === "location") {
				content =
					currentWellData[infoKey].charAt(0).toUpperCase() +
						currentWellData[infoKey].slice(1) || "-";
			}
		} else if (infoKey === "actual_start_date") {
			if (currentWellData?.actual_start_date) {
				var date = new Date(currentWellData.actual_start_date);
				// change the date format to "1 Mar 2024"
				content = date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			} else {
				content = "-";
			}
		} else if (infoKey === "planned_end_date") {
			if (
				currentWellData?.planned_start_date &&
				currentWellData?.estimated_duration_days
			) {
				var date = new Date(currentWellData.planned_start_date);
				date.setDate(date.getDate() + currentWellData?.estimated_duration_days);
				// change the date format to "1 Mar 2024"
				content = date.toLocaleDateString("en-GB", {
					year: "numeric",
					month: "short",
					day: "numeric",
				});
			} else {
				content = "-";
			}
		}
		return content;
	};

	useEffect(() => {
		let filteredTaskList = null;
		if (searchValue != "") {
			let filteredTask: any = {};
			// Object.keys(taskList)?.forEach((taskKeys: any) => {
			// 	console.log(taskKeys);
			// 	filteredTask[taskKeys] = taskList?.[taskKeys]?.filter(
			// 		(task: any) =>
			// 			task?.task_name
			// 				?.toLowerCase()
			// 				.includes(searchValue.toLowerCase()) ||
			// 			task.task_number === searchValue
			// 	);
			// });

			filteredTask = taskList.filter(
				(task: any) =>
					task?.task_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
					task.task_number === searchValue
			);
			filteredTaskList = filteredTask;
		} else {
			filteredTaskList = taskList;
		}

		const check = Object.keys(selectedFilters).some(
			(filterKey: any) => selectedFilters[filterKey].length > 0
		);
		if (check) {
			filteredTaskList = filteredTaskList.filter((task: any) =>
				Object.keys(selectedFilters).every((filterKey: any) => {
					if (selectedFilters[filterKey].length == 0) {
						return true;
					}
					if (typeof task[filterKey] == "object") {
						return task[filterKey].some((key: any) =>
							selectedFilters[filterKey].includes(key)
						);
					} else {
						return selectedFilters[filterKey].includes(task[filterKey]);
					}
				})
			);
		}

		setFilteredTaskList(filteredTaskList);
	}, [searchValue, selectedFilters]);

	useEffect(() => {
		filterToggleData(phaseList);
	}, [hideExcluded]);

	const filterToggleData = (data: any) => {
		if (hideExcluded) {
			const filteredPhaseList = data?.filter((phase: any) => {
				return !phase.disabled;
			});
			setFilteredPhaseList(filteredPhaseList);
		} else {
			setFilteredPhaseList(data);
		}
	};

	useEffect(() => {
		if (context.currentTenantKey && context.allData) {
			let project_code: any =
				location?.state?.project_code || location?.pathname?.split("/")[2];
			let well_project_code: any =
				location?.state?.well_project_code || location?.pathname?.split("/")[3];

			getProject(context.currentTenantKey, project_code)
				.then((project: any) => {
					if (project) {
						setProjectTitle(project.project_name);
						setProjectInfo(project);
						let role1 = [
							{ label: "Project manager", value: "project_manager" },
						];
						let role2 = project.role_assignments.map((role: any) => {
							return { label: role.role, value: role.role_id };
						});
						let roles = role1.concat(role2);
						setProjectRoleAssignmentList(roles);
						context.setCurrentProject(project);
						getCampaignBasedWellProjects(context.currentTenantKey, {
							project_code,
							well_project_code,
						})
							.then((data: any) => {
								setWellTitle(data[0].well_project_name);
								setWellInfo(data[0]);
								context.setCurrentWell(data[0]);

								getPhasesAndTasks(context.currentTenantKey, {
									well_project_code: data[0].well_project_code,
								}).then((data: any) => {
									setPhaseList(data?.phase_data || []);
									filterToggleData(data?.phase_data || []); // set filteredPhaseList to phaseList
									const taskIDArray = data?.taskList?.map(
										(task: any) => task._id
									);
									console.log("i-debug taskid array", taskIDArray);
									getDeliverablesGeneral(context.currentTenantKey, taskIDArray)
										.then((deliverableList: any) => {
											deliverableList?.forEach((deliverable: any) => {
												let responsibleRoles =
													deliverable.responsible_roles || [];
												let accountable_roles =
													deliverable.accountable_roles || [];
												let consulted = deliverable.consulted || [];
												let informed_parties =
													deliverable.informed_parties || [];
												let foundTaskIndex = data?.taskList.findIndex(
													(task: any) => task._id === deliverable.task_id
												);
												if (foundTaskIndex != -1) {
													if (!data.taskList[foundTaskIndex].assigned_to) {
														data.taskList[foundTaskIndex].assigned_to = [];
													}
													data.taskList[foundTaskIndex].assigned_to =
														data.taskList[foundTaskIndex].assigned_to
															.concat(responsibleRoles)
															.concat(accountable_roles)
															.concat(consulted)
															.concat(informed_parties)
															.filter(
																(item: any, index: any, arr: any) =>
																	arr.indexOf(item) === index
															);
												}
											});
											setIsLoading(false);
											setTaskList(data?.taskList);
											if (localStorage.getItem("selectedFilters")) {
												let selectedFiltersStore: any =
													localStorage.getItem("selectedFilters");
												let selectedFiltersStoreJson =
													JSON.parse(selectedFiltersStore);
												setSelectedFilters(selectedFiltersStoreJson);
												setFilterMode(true);
											}
										})
										.catch((error: any) => {
											console.log(error);
										});
								});
							})
							.catch((error: any) => {
								setWellTitle(null);
								setWellInfo(null);
								setIsLoading(false);
							});
					} else {
						setProjectTitle(null);
						setProjectInfo(null);
						setIsLoading(false);
					}
				})
				.catch((e: any) => {
					setProjectTitle(null);
					setProjectInfo(null);
					setIsLoading(false);
				});
		}
	}, [context.currentTenantKey, context.allData]);

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};
	const handleMenuClick = (field: any, selectedValues: any[]) => {
		// console.log(`Selected: ${field} - ${selectedValues}`);
		// Update the selected filters state
		setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

		localStorage.setItem(
			"selectedFilters",
			JSON.stringify({ ...selectedFilters, [field]: selectedValues })
		);
	};

	const handleClearAllFilters = () => {
		// Reset all filters to empty arrays
		setSelectedFilters({
			assigned_to: [],
			status: [],
		});
		setFilterMode(false);

		// Clear session storage for filter values and checkboxes
		localStorage.removeItem("selectedFilters");

		// Object.keys(selectedFilters).forEach((field) => {
		// 	const storageKey = generateStorageKey(field);
		// 	localStorage.removeItem(storageKey);
		// });

		setTimeout(() => {
			setFilterMode(true);
		}, 5);
	};

	const renderMetaData = () => {
		const numRows = Math.ceil(Object.keys(WellTemplateExample).length / 2);
		const totalColumns = numRows * 2;
		const leftOverColumns = totalColumns - WellTemplateExample.length;

		return (
			<>
				<div
					style={{
						display: "grid",
						columnGap: "20px",
						rowGap: "20px",
						// gridTemplateRows: `repeat(${numRows}, auto)`,
						gridTemplateColumns: "repeat(3, auto)",
					}}
				>
					{WellTemplateExample.map((element: any, index: any) => (
						<div
							key={index}
							style={{
								display: "grid",
								gridTemplateRows: "20px auto",
							}}
						>
							<span
								style={{
									height: "min-content",
									color: "rgba(255, 255, 255, 0.45)",
									textAlign: "left",
									fontSize: "12px",
									whiteSpace: "nowrap",
								}}
							>
								{element.label}
							</span>
							<span
								style={{
									fontSize: "14px",
									textAlign: "left",
									height: "min-content",
								}}
							>
								{setWellContent(wellInfo, element.key)}
							</span>
						</div>
					))}
					{Array.from({ length: leftOverColumns }, (_, index) => (
						<div
							style={{
								display: "grid",
								rowGap: "10px",
								gridTemplateRows: "auto auto",
							}}
						>
							<span key={index}>{""}</span>
							<span key={index}>{""}</span>
						</div>
					))}
				</div>
				<div
					style={{
						display: "grid",
						columnGap: "20px",
						gridTemplateRows: "20px auto",
						background: "rgba(255, 255, 255, 0.04)",
						padding: "20px",
						flexGrow: 1,
					}}
				>
					<span
						style={{
							// height: "min-content",
							color: "rgba(255, 255, 255, 0.45)",
							textAlign: "left",
							fontSize: "12px",
						}}
					>
						Project comment
					</span>
					<span
						style={{
							fontSize: "14px",
							textAlign: "left",
							// height: "min-content",
						}}
					>
						<ExpandableText
							paragraphText={setWellContent(wellInfo, "well_project_comments")}
							rows={5}
							containerId="main-container"
						/>
					</span>
				</div>
			</>
		);
	};

	const renderDetailData = () => {
		const numRows = Math.ceil(Object.keys(WellDetailsExample).length / 3);
		const totalColumns = numRows * 3;
		const leftOverColumns = totalColumns - WellDetailsExample.length;

		return (
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					background: "rgba(31,31,31,0.2)",
					padding: "20px",
					gap: "20px",
				}}
			>
				<div
					style={{
						display: "grid",
						columnGap: "20px",
						rowGap: "20px",
						gridTemplateRows: `repeat(4, auto)`,
						gridTemplateColumns: "repeat(3, auto)",
						gridAutoFlow: "column",
						flexGrow: 1,
					}}
				>
					{WellDetailsExample.map((element: any) => (
						<div
							style={{
								display: "grid",
								columnGap: "20px",
								gridTemplateRows: "20px auto",
							}}
						>
							<span
								style={{
									height: "min-content",
									color: "rgba(255, 255, 255, 0.45)",
									textAlign: "left",
									fontSize: "12px",
								}}
							>
								{element.label}
							</span>
							<span
								style={{
									fontSize: "14px",
									textAlign: "left",
									height: "min-content",
								}}
							>
								{setWellContent(wellInfo, element.key)}
							</span>
						</div>
					))}
					{Array.from({ length: leftOverColumns }, (_, index) => (
						<div
							style={{
								display: "grid",
								rowGap: "10px",
								gridTemplateRows: "auto auto",
							}}
						>
							<span key={index}>{""}</span>
							<span key={index}>{""}</span>
						</div>
					))}
				</div>
				<div
					style={{
						display: "grid",
						columnGap: "20px",
						// alignItems: "center",
						// alignContent: "center",
						// gridTemplateColumns: "50% 50%",
						gridTemplateRows: "20px auto",
						background: "rgba(255, 255, 255, 0.04)",
						padding: "20px",
						flexGrow: 1,
					}}
				>
					<span
						style={{
							height: "min-content",
							color: "rgba(255, 255, 255, 0.45)",
							textAlign: "left",
							fontSize: "12px",
						}}
					>
						Well comment
					</span>
					<span
						style={{
							fontSize: "14px",
							textAlign: "left",
							verticalAlign: "top",
							height: "min-content",
						}}
					>
						<ExpandableText
							paragraphText={setWellContent(wellInfo, "well_comments")}
							rows={5}
							containerId="main-container"
						/>
					</span>
				</div>
			</div>
		);
	};

	const renderKanbanColumns = () => {
		const renderTasks = (phase: any) => {
			let currentTaskList = filteredTaskList || taskList;
			// check again for excluded tasks if hideExcluded is false
			if (hideExcluded) {
				currentTaskList = currentTaskList.filter((task: any) => !task.disabled);
			}
			let PhasetaskList = currentTaskList.filter(
				(element: any) => element.phase_id == phase._id
			);
			return PhasetaskList?.map((task: any) => {
				const disabled = task.disabled || false;
				const comment = task.disabled_reason || "";
				const tooltipText =
					!disabled || comment == "" ? (
						""
					) : (
						<span>
							This task is excluded from the well programme. You may still click
							to view the details. <br /> <br />
							<b>Comment:</b> <br />
							{comment}
						</span>
					);
				return (
					<div
						className={!disabled ? "task-item" : "task-item task-item-disabled"}
						onClick={() => {
							// if (checkPrivilege(props.params.userRole, ["project-manager"])) {
							navigate(`${phase._id}/${task._id}`, {
								replace: false,
								state: { task_id: task._id },
							});
							// }
						}}
						onContextMenu={(e: any) => {
							const location = `${window.location.href}/${phase._id}/${task._id}`;
							handleContextMenu(e, { link: location });
						}}
					>
						<Tooltip title={tooltipText}>
							<span style={{ display: "flex", gap: "5px" }}>
								{disabled && excludedMark()}
								{task.task_name}
							</span>
						</Tooltip>
						{statusIcon(task.status)}
					</div>
				);
			});
		};

		return filteredPhaseList?.map((phase: any) => {
			const currentTaskList = filteredTaskList || taskList;
			const disabled = phase.disabled || false;
			const comment = phase.disabled_reason || "";
			const tooltipText = !disabled ? (
				"Click for phase overview"
			) : (
				<span>
					This phase is excluded from the well programme. You may still click
					for phase overview. <br /> <br />
					<b>Comment:</b> <br />
					{comment}
				</span>
			);
			// var percentage = 0;
			// let PhasetaskList = currentTaskList.filter(
			// 	(element: any) => element.phase_id == phase._id
			// );
			// if (PhasetaskList) {
			// const completedTasks = PhasetaskList.filter(
			// 	(task: any) => task.status === "Resolved"
			// );
			// if (completedTasks.length > 0) {
			// 	percentage = Math.floor(
			// 		(completedTasks.length / PhasetaskList.length) * 100
			// 	);
			// }
			// }

			return (
				<div
					style={{
						minWidth: "320px",
						maxWidth: "320px",
						height: "100%",
						display: "flex",
						flexDirection: "column",
						gap: "30px",
					}}
				>
					<div className="phase-progress-bar">
						<span style={{ zIndex: "1" }}>
							{disabled ? "-" : <>{Math.round(phase.progress) || 0}%</>}
						</span>
						{!disabled && (
							<div
								style={{
									left: "0",
									position: "absolute",
									width: `${Math.round(phase.progress) || 0}%`,
									height: "46px",
									background:
										"linear-gradient(90deg, #225C61 0%, #299BA3 100%)",
									borderRadius: "3px",
								}}
							></div>
						)}
					</div>
					<div
						className="project-status-container phase-container"
						style={{
							minWidth: "320px",
							maxWidth: "320px",
							flex: 1,
							height: "100px",
						}}
					>
						<div
							className={
								!disabled
									? "project-status-container-header phase-item"
									: "project-status-container-header phase-item phase-item-disabled"
							}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
							onClick={() => {
								navigate(`${phase._id}`, {
									replace: false,
									state: { phase_id: phase._id },
								});
							}}
							onContextMenu={(e: any) => {
								const location = `${window.location.href}/${phase._id}`;
								handleContextMenu(e, { link: location });
							}}
						>
							<img src={squareHehe} />
							<Tooltip title={tooltipText}>
								<span style={{ display: "flex", gap: "5px" }}>
									{disabled && excludedMark()}
									{phase.phase_name}
								</span>
							</Tooltip>
						</div>
						<div
							className={
								!disabled
									? "project-status-container-content task-container"
									: "project-status-container-content task-container task-container-disabled"
							}
							style={{ padding: "0", gap: "0px" }}
						>
							{renderTasks(phase)}
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			{projectInfo && wellInfo && !isLoading && (
				<div
					className="generic-container"
					style={{ gap: "20px", overflow: "auto" }}
				>
					<Space direction="vertical">
						<span
							style={{
								fontSize: "20px",
								fontWeight: "600",
								color: "rgba(255, 255, 255, 0.85)",
							}}
						>
							{/* {projectTitle} / {wellTitle} */}
						</span>
					</Space>
					{/* Top Container */}
					<div
						className="main-container"
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
						}}
						id="main-container"
					>
						<div
							className="container-header"
							style={{
								display: "flex",
								padding: "12px 30px",
								minHeight: "56px",
								alignItems: "center",
								borderRadius: "3px",
								background: "#0D5257",
								boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
							}}
						>
							<div
								className={""}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setWellDetailsExpand(!wellDetailsExpand);
								}}
							>
								<span>Well project details</span>
								{wellDetailsExpand ? GetAntIcon("up") : GetAntIcon("downarrow")}
							</div>{" "}
						</div>
						{wellDetailsExpand ? (
							<div
								className="details-content project-details-container"
								style={{
									display: "flex",
									flexWrap: "inherit",
									flexDirection: "row",
									alignItems: "flex-start",
									justifyContent: "space-between",
									padding: "30px",
									gap: 0,
									overflowX: "auto",
									maxHeight: "100%",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										maxWidth: "30%",
										height: "100%",
										position: "relative",
										gap: "20px",
									}}
								>
									{renderMetaData()}
									<div></div>
									<div
										style={{
											marginTop: "auto",
											display: "flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										{checkPrivilege(props.params.userRole, [
											"project-manager",
										]) && (
											<a
												style={{ marginTop: "auto" }}
												onClick={() => {
													navigate("well-settings", {
														replace: false,
														state: { ...projectInfo, ...wellInfo },
													});
												}}
												onContextMenu={(e: any) => {
													const location = `${window.location.href}/well-settings`;
													handleContextMenu(e, { link: location });
												}}
											>
												{GetAntIcon("setting")} Well settings
											</a>
										)}
										<a
											onClick={() => {
												setAuditOpen(true);
											}}
										>
											{GetAntIcon("history")} Audit log
										</a>
									</div>
								</div>

								<Divider
									type="vertical"
									style={{ height: "100%", margin: "0px 20px" }}
								></Divider>
								<div
									style={{
										flex: "1",
										// background: "rgba(31,31,31,0.2)",
										// padding: "0px 20px",
									}}
								>
									<Divider
										orientation="left"
										style={{
											fontSize: "14px",
											color: "rgba(255, 255, 255, 0.45)",
											marginTop: "0px",
										}}
									>
										Well details
									</Divider>
									<div>{renderDetailData()}</div>
									{/* <Table dataSource={[]} columns={[]} /> */}
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
					{/* Bottom Container */}
					<div
						className="main-container"
						style={{
							minHeight: "85%",
							flex: 1,
							width: "100%",
							// maxHeight: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							className="project-header"
							style={{
								display: "flex",
								padding: "12px 30px",
								alignItems: "center",
								borderRadius: "3px",
								background: "#0D5257",
								boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
							}}
						>
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<div
									className={
										activePage === "kanban" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("kanban");
									}}
								>
									{GetAntIcon("project")}
									<span>Kanban</span>
								</div>{" "}
								{/* <div
									className={
										activePage === "ganttChart" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("ganttChart");
									}}
								>
									{GetAntIcon("app")}
									<span>Gantt chart</span>
								</div> */}
							</div>
							<div
								style={{
									display: "flex",
									flex: "1",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "15px",
								}}
							>
								<Input
									placeholder="Search by task name"
									className="input-clearable"
									style={{ maxWidth: "265px" }}
									onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
										setSearchValue(e.currentTarget.value);
										e.currentTarget.blur();
									}}
									onChange={(e) => {
										if (e.target.value === "") {
											setSearchValue("");
										}
									}}
									allowClear
									prefix={<SearchOutlined />}
								/>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{" "}
									<div
										className={
											filterMode || hasSelectedFilters()
												? "project-active-button"
												: ""
										}
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setFilterMode(!filterMode);
										}}
									>
										{GetAntIcon("filter")}
									</div>
									{/* <div
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setDescMode(!descMode);
										}}
									>
										{descMode
											? GetAntIcon("descending")
											: GetAntIcon("ascending")}
									</div> */}
									{/* <div style={{ padding: "4px 10px", cursor: "pointer" }}>
										{GetAntIcon("setting")}
									</div> */}
								</div>
							</div>
						</div>
						<div
							className="generic-content project-overview-container"
							style={{
								flex: 1,
								display: "flex",
								flexWrap: "inherit",
								flexDirection: "column",
								alignItems: "flex-start",
								padding: 0,
								gap: 0,
								overflowX: "auto",
								maxHeight: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									padding: filterMode ? "12px 30px" : "0 30px",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "5px",
									transform: filterMode ? "translateY(0)" : "translateY(-150%)",
									alignSelf: "stretch",
									background: "rgba(6,45,48)",
									transition: "0.3s ease-in-out",
								}}
							>
								{filterMode && (
									<>
										{" "}
										Hide excluded items
										<Switch
											// checkedChildren="Hide excluded items"
											// unCheckedChildren="Show excluded items"
											defaultValue={hideExcluded}
											onChange={(checked: any) => {
												setHideExcluded(checked);
											}}
										/>
										<CheckboxDropdown
											options={projectRoleAssignmentList}
											onChange={(selectedValues) =>
												handleMenuClick("assigned_to", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.assigned_to}
											Key="assigned_to"
											label="Assigned to"
										/>
										<CheckboxDropdown
											options={[
												{ label: "New", value: "New" },
												{
													label: "To do",
													value: "To do",
												},
												{ label: "In Progress", value: "In progress" },
												{ label: "Late", value: "Late" },
												{
													label: "Resolved",
													value: "Resolved",
												},
											]}
											onChange={(selectedValues) =>
												handleMenuClick("status", selectedValues)
											}
											onClear={""}
											defaultValue={selectedFilters.status}
											Key="status"
											label="Status"
										/>
										<div className="test">
											<Button
												type="link"
												style={{ color: "rgba(255, 255, 255, 0.5)" }}
												onClick={handleClearAllFilters}
											>
												Reset All
											</Button>
										</div>
									</>
								)}
							</div>
							{activePage === "kanban" && (
								<div
									className="kanban-columns-container"
									style={{
										display: "flex",
										flex: 1,
										alignItems: "flex-start",
										padding: "30px",
										gap: "25px",
										alignSelf: "stretch",
										overflow: "auto",
									}}
								>
									{phaseList?.length > 0 ? (
										renderKanbanColumns()
									) : (
										<Empty
											className="no-data-empty"
											style={{ margin: "auto", fontSize: "20px" }}
											image={elementalSvg}
											description="Programme for well is not built yet."
										/>
									)}
								</div>
							)}
						</div>
					</div>
					<AuditLogModal
						tenantKey={context?.currentTenantKey}
						identifierKeys={{
							project_code: projectInfo?.project_code,
							well_project_code: wellInfo?.well_project_code,
						}}
						type={"well_project"}
						label={"Well project"}
						open={auditOpen}
						handleClose={() => {
							setAuditOpen(false);
						}}
					/>
				</div>
			)}
			{!projectInfo && !isLoading && (
				<>
					<NoAccess text={"This project does not exist."} />
				</>
			)}
			{!wellInfo && projectInfo && !isLoading && (
				<>
					<NoAccess text={"This well does not exist."} />
				</>
			)}
		</>
	);
}

export default WellOverview;
