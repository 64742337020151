import React, { useEffect } from "react";
import { Space, Input, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Emitter from "../../utils/emitter";

const defaultMarkdownFile = require("./WellDeliveredNoContent.md");

const WellDelivered = (props: any) => {
	const [tabKey, setTabKey] = React.useState(props.params.contents[0].key);
	const [tab, setTab] = React.useState(props.params.contents[0]);
	const [descendants, setDescendants] = React.useState(null);
	// const [tabContents, setTabContents] = React.useState(
	// 	props.params.contents[0].contents
	// );
	const [markdownContent, setMarkdownContent] = React.useState("");

	useEffect(() => {
		const tabContent: any = props.params.contents.find(
			(item: any) => item.key === tabKey
		);
		fetch(tabContent.markdownContent)
			.then((res) => res.text())
			.then((text) => setMarkdownContent(text))
			.catch((error) => {
				console.error("Error fetching Markdown content:", error);
			});
	}, []);

	const handleTabChange = (key: any) => {
		setTabKey(key);
		setTab(props.params.contents.find((item: any) => item.key === key));
		const tabContent: any = props.params.contents.find(
			(item: any) => item.key === key
		);
		if (tabContent.descendants) {
			const descendant = tabContent.descendants[0];
			// console.log("i-debug descendants", descendant);
			fetch(descendant.markdownContent)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					console.error("Error fetching Markdown content:", error);
				});
		} else if (tabContent.markdownContent) {
			fetch(tabContent.markdownContent)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					console.error("Error fetching Markdown content:", error);
				});
		} else {
			fetch(defaultMarkdownFile)
				.then((res) => res.text())
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					console.error("Error fetching Markdown content:", error);
				});
		}
	};

	const handleExtraTabChange = async (key: any) => {
		// console.log("i-debug key", key);
		const descendant = tab.descendants.find((item: any) => item.key === key);
		// console.log("i-debug descendants", descendant);
		fetch(descendant.markdownContent)
			.then((res) => res.text())
			.then((text) => setMarkdownContent(text))
			.catch((error) => {
				console.error("Error fetching Markdown content:", error);
			});
	};

	// useEffect(() => {
	// 	console.log("i-debug tab", tab);
	// 	Emitter.emit("loading", true);
	// 	// if (tab.descendants) {
	// 	// 	// setDescendants(tab.descendants[0]);
	// 	// 	if (tab.descendants.markdownContent) {
	// 	// 		const response = fetch(tab.descendants[0].markdownContent)
	// 	// 			.then((res) => res.text())
	// 	// 			.then((text) => setMarkdownContent(text))
	// 	// 			.catch((error) => {
	// 	// 				console.error("Error fetching Markdown content:", error);
	// 	// 			});
	// 	// 	}
	// 	// } else if (tab.markdownContent) {
	// 	// 	const response = fetch(tab.markdownContent)
	// 	// 		.then((res) => res.text())
	// 	// 		.then((text) => setMarkdownContent(text))
	// 	// 		.catch((error) => {
	// 	// 			console.error("Error fetching Markdown content:", error);
	// 	// 		});
	// 	// } else {
	// 	// 	const response = fetch(defaultMarkdownFile)
	// 	// 		.then((res) => res.text())
	// 	// 		.then((text) => setMarkdownContent(text))
	// 	// 		.catch((error) => {
	// 	// 			console.error("Error fetching Markdown content:", error);
	// 	// 		});
	// 	// }
	// 	Emitter.emit("loading", false);
	// }, [tab]);

	// console.log("i-debug props", props);

	const markdownStyles = {
		Heading: ({ children }: any) => (
			<span
				style={{
					fontSize: "16px",
					fontWeight: "600",
					lineHeight: "20px",
					borderBottom: "1px solid rgba(125, 125, 125, 1)",
					paddingBottom: "8px",
				}}
			>
				{children}
			</span>
		),

		Paragraph: ({ children }: any) => (
			<p
				style={{
					fontSize: "14px",
					fontWeight: "400",
					color: "rgba(255, 255, 255, 0.85)",
					lineHeight: "25px",
					marginTop: "0",
					marginBottom: "0",
				}}
			>
				{children}
			</p>
		),

		UnorderedList: ({ children }: any) => (
			<ul
				style={{
					paddingInlineStart: "20px",
					fontSize: "14px",
					fontWeight: "400",
					color: "rgba(255, 255, 255, 0.85)",
					lineHeight: "25px",
					marginTop: "0",
					marginBottom: "0",
				}}
			>
				{children}
			</ul>
		),

		OrderedList: ({ children }: any) => (
			<ol
				style={{
					paddingInlineStart: "20px",
					fontSize: "14px",
					fontWeight: "400",
					color: "rgba(255, 255, 255, 0.85)",
					lineHeight: "25px",
					marginTop: "0",
					marginBottom: "0",
				}}
			>
				{children}
			</ol>
		),
	};

	return (
		<div className="generic-container" style={{ gap: "20px" }}>
			<Space direction="vertical">
				<span
					style={{
						fontSize: "20px",
						fontWeight: "600",
						color: "rgba(255, 255, 255, 0.85)",
					}}
				>
					{props.params.propTitle}
				</span>
			</Space>
			<div
				className="main-container"
				style={{
					flex: 1,
					width: "100%",
					maxHeight: "100%",
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div
					className="generic-header"
					style={{ height: "50px", justifyContent: "space-between" }}
				>
					Guides
					{/* <Input
						placeholder="Search"
						className="input-clearable"
						style={{ maxWidth: "265px" }}
						allowClear
						prefix={<SearchOutlined />}
					/> */}
				</div>
				<div
					className="generic-content"
					style={{
						flex: "1",
						gap: "0px",
						maxHeight: "100%",
						overflow: "hidden",
						flexWrap: "nowrap",
					}}
				>
					<Tabs
						style={{ lineHeight: "1.55" }}
						tabPosition="left"
						onChange={handleTabChange}
						activeKey={tabKey}
						items={props.params.contents}
					/>
					<div
						style={{
							flex: "1",
							height: "100%",
							display: "flex",
							maxWidth: "100%",
							overflow: "auto",
							flexDirection: "column",
							gap: "20px",
							padding: "30px 0px",
						}}
					>
						{tab.descendants ? (
							<>
								<Tabs items={tab.descendants} onChange={handleExtraTabChange} />
								<ReactMarkdown
									children={markdownContent}
									components={{
										h1: markdownStyles.Heading,
										p: markdownStyles.Paragraph,
										ul: markdownStyles.UnorderedList,
										ol: markdownStyles.OrderedList,
									}}
									rehypePlugins={[rehypeRaw]}
								/>
							</>
						) : (
							<ReactMarkdown
								children={markdownContent}
								components={{
									h1: markdownStyles.Heading,
									p: markdownStyles.Paragraph,
									ul: markdownStyles.UnorderedList,
									ol: markdownStyles.OrderedList,
								}}
								rehypePlugins={[rehypeRaw]}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WellDelivered;
