import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Space, Tabs } from "antd";
import { HomeContext } from "../Home";
import CRUDTabTableModal from "../../components/CRUDTabTableModal";

function AdminPage(props: any) {
	const context: any = useContext(HomeContext);
	const [tabkey, setTabKey] = useState(() => {
		return sessionStorage.getItem("admin-tabkey") || "tenant-manager";
	});

	const changeContent = () => {
		switch (tabkey) {
			case "tenant-manager":
				return [
					{ label: "Tenant directory", key: "tenant" },
					{ label: "Tenant product", key: "tenant-product" },
				];
			case "lookup-manager":
				return [
					{ label: "Company", key: "company" },
					{ label: "Country", key: "country" },
					{ label: "Operating office", key: "operating_office" },
					{ label: "Operation type", key: "operation_type" },
					{
						label: "Tag manager",
						key: "project_tags",
						directoryName: "Tag manager",
					},
					{ label: "Region", key: "region" },
					{
						label: "Strategic business unit",
						key: "strategic_business_unit",
					},
					{ label: "Well purpose", key: "well_purpose" },
					{ label: "Well status", key: "well_status" },
				];
			case "roles-manager":
				return [{ label: "Role & permissions", key: "roles_permissions" }];
			case "template":
				return [
					{
						label: "Well programme",
						key: "well_programme_template",
						directoryName: "Well programme directory",
						configurationName: "Well programme configuration",
					},
				];
			default:
				return <></>;
		}
	};

	const handleTabChange = async (key: any) => {
		try {
			await context.handlePageChange();
			setTabKey(key);
			sessionStorage.setItem("admin-tabkey", key);
			sessionStorage.removeItem("tabkey");
		} catch {}
	};

	return (
		<div className="generic-container" style={{ gap: "10px" }}>
			<Space direction="vertical">
				<span
					style={{
						fontSize: "20px",
						fontWeight: "600",
						color: "rgba(255, 255, 255, 0.85)",
					}}
				>
					{/* {props.params.proptitle} */}
				</span>
			</Space>

			<Tabs
				onChange={handleTabChange}
				activeKey={tabkey}
				items={[
					{ label: "Tenant management", key: "tenant-manager" },
					{ label: "Role & permissions", key: "roles-manager" },
					{ label: "Lookup management", key: "lookup-manager" },
					{ label: "Template", key: "template" },
				]}
			></Tabs>
			<CRUDTabTableModal
				items={changeContent()}
				tabkey={tabkey}
				user={props.params?.user}
			/>
		</div>
	);
}

export default AdminPage;
