// import { v4 } from "uuid";
// import CryptoJS from "crypto-js";
// import {
// 	addBusinessUnitItem,
// 	deleteBusinessUnitItem,
// 	getBusinessUnitList,
// 	updateBusinessUnitItem,
// } from "../services/api-server/business_unit";
// import {
// 	addProjectTagItem,
// 	deleteProjectTagItem,
// 	getProjectTagList,
// 	updateProjectTagItem,
// } from "../services/api-server/project_tags";
// import {
// 	addOperatingOfficeItem,
// 	deleteOperatingOfficeItem,
// 	getOperatingOfficeList,
// 	updateOperatingOfficeItem,
// } from "../services/api-server/operating_office";
// import {
// 	addOperationTypeItem,
// 	deleteOperationTypeItem,
// 	getOperationTypeList,
// 	updateOperationTypeItem,
// } from "../services/api-server/operation_type";
// import SearchButton from "../components/SearchButton";
// import { countryList } from "./countryCodes";
// import {
// 	addTagGroupItem,
// 	deleteTagGroupItem,
// 	getTagGroupItem,
// 	getTagGroupList,
// 	updateTagGroupItem,
// } from "../services/api-server/tag_group";
// import {
// 	addRegionItem,
// 	deleteRegionItem,
// 	getRegionList,
// 	updateRegionItem,
// } from "../services/api-server/region";
// import {
// 	addCompanyItem,
// 	deleteCompanyItem,
// 	getCompanyList,
// 	updateCompanyItem,
// } from "../services/api-server/company";
// import {
// 	addRolePermissionList,
// 	deleteRolePermissionList,
// 	getRolePermissionList,
// 	updateRolePermissionList,
// } from "../services/api-server/roles_permissions";
// import { getAppRoles } from "../services/api-server/graphql";
// import {
// 	addTenantItem,
// 	getTenantList,
// 	updateTenantItem,
// } from "../services/api-server/tenant_management";
// import {
// 	getWellPurposeTypeList,
// 	addWellPurposeTypeItem,
// 	updateWellPurposeTypeItem,
// 	deleteWellPurposeTypeItem,
// } from "../services/api-server/purpose_types";
// import {
// 	getWellStatusTypeList,
// 	addWellStatusTypeItem,
// 	updateWellStatusTypeItem,
// 	deleteWellStatusTypeItem,
// } from "../services/api-server/status_types";
// import {
// 	getWellPurposeList,
// 	addWellPurposeItem,
// 	updateWellPurposeItem,
// 	deleteWellPurposeItem,
// } from "../services/api-server/purposes";
// import {
// 	getWellStatusList,
// 	addWellStatusItem,
// 	updateWellStatusItem,
// 	deleteWellStatusItem,
// } from "../services/api-server/status";
// import {
// 	addWellProjectItem,
// 	getWellProjects,
// 	updateWellProjectItem,
// 	deleteWellProjectItem,
// 	getWellProjectCode,
// 	getCampaignBasedWellProjects,
// } from "../services/api-server/well_projects";
// import {
// 	addWellProgrammeTemplate,
// 	getWellProgrammeTemplateList,
// 	updateWellProgrammeTemplate,
// 	deleteWellProgrammeTemplate,
// 	findAndUpdateWellProgrammeTemplate,
// } from "../services/api-server/well_programme_template";
// import { superAdminRole } from "./_exports";
// import Emitter from "./emitter";
// import { from } from "rxjs";
// import {
// 	addPhaseItem,
// 	deletePhaseItem,
// 	getPhasesAndTasks,
// 	updatePhaseItem,
// } from "../services/api-server/phases";
// import { Button, Tag } from "antd";
// import { setLookupData } from "./lookupFinder";
// import {
// 	addTaskItem,
// 	deleteTaskItem,
// 	updateTaskItem,
// } from "../services/api-server/tasks";
// import {
// 	addDeliverableItem,
// 	deleteDeliverableItem,
// 	getDeliverables,
// 	updateDeliverableItem,
// } from "../services/api-server/deliverables";
// import { GetAntIcon } from "./ant_icons";

export const getCrudaClass = async (lookupkey: any) => {
	try {
		const module = await import(`./CRUDA/${lookupkey}`);
		const ClassInstance = new module.default();
		return ClassInstance;
	} catch (error) {
		throw error;
	}
};

export const isAddButtonVisible = (lookupKey: any) => {
	const disabledKeys = ["country", "tenant"];
	return !disabledKeys.includes(lookupKey);
};

export const isDeleteButtonVisible = (lookupKey: any) => {
	const disabledKeys = ["tenant"];
	return !disabledKeys.includes(lookupKey);
};

// export const getColumnList = (
// 	lookupKey: any,
// 	handleSearch: any,
// 	allData: any = null,
// 	extraData: any = null,
// 	option: any = null
// ) => {
// 	switch (lookupKey) {
// 		case "strategic_business_unit":
// 			return businessUnitColumnKeys(lookupKey, handleSearch);
// 		case "project_tags":
// 			return projectTagsColumnKeys(lookupKey, handleSearch);
// 		case "operating_office":
// 			return operatingOfficeColumnKeys(lookupKey, handleSearch);
// 		case "operation_type":
// 			return operationTypeColumnKeys(lookupKey, handleSearch);
// 		case "country":
// 			return countryColumnKeys(lookupKey, handleSearch);
// 		case "region":
// 			return regionColumnList(lookupKey, handleSearch);
// 		case "company":
// 			return companyColumnList(lookupKey, handleSearch);
// 		case "roles_permissions":
// 			return rolesColumnList(lookupKey, handleSearch);
// 		case "operation_type":
// 			return;
// 		case "tenant":
// 			return tenantManagmentColumnList(lookupKey, handleSearch);
// 		case "well_purpose":
// 			return wellPurposeColumnList(lookupKey, handleSearch);
// 		case "well_status":
// 			return wellStatusColumnList(lookupKey, handleSearch);
// 		case "well_project":
// 			return wellManagementColumnList(lookupKey, handleSearch, allData);
// 		case "phase":
// 			switch (option) {
// 				case "template":
// 					return phaseTemplateColumnList(lookupKey, handleSearch, extraData);
// 				default:
// 					return phaseColumnList(lookupKey, handleSearch, extraData);
// 			}

// 		case "deliverable":
// 			switch (option) {
// 				case "template":
// 					return deliverableTemplateColumnList(
// 						lookupKey,
// 						handleSearch,
// 						extraData
// 					);
// 				default:
// 					return deliverableColumnList(lookupKey, handleSearch, allData);
// 			}
// 		case "well_programme_template":
// 			return wellProgrammeTemplateColumnList(lookupKey, handleSearch);
// 		default:
// 			return null;
// 	}
// };

// export const getFormOptionList = (
// 	lookupKey: any,
// 	option: any = null,
// 	record: any = null,
// 	navigateHook: any = null
// ) => {
// 	switch (lookupKey) {
// 		case "strategic_business_unit":
// 			return businessUnitFormList;
// 		case "project_tags":
// 			switch (option) {
// 				case "tag":
// 					return projectTagsFormList;
// 				case "tag_group":
// 					return projectTagGroupFormList;
// 			}
// 			break;
// 		case "operating_office":
// 			return operatingOfficeFormList;
// 		case "operation_type":
// 			return operationTypeFormList;
// 		case "country":
// 			return;
// 		case "region":
// 			return regionFormList;
// 		case "company":
// 			return companyFormList;
// 		case "roles_permissions":
// 			return rolesFormList;
// 		case "project-details":
// 			return projectDetailsFormList;
// 		case "roles-configuration":
// 			return rolesConfigurationFormList;
// 		case "tenant":
// 			return tenantManagementFormList;
// 		case "help-center":
// 			return helpCenterFormList;
// 		case "well_purpose":
// 			switch (option) {
// 				case "well_purpose":
// 					return wellPurposeFormList;
// 				case "well_purpose_type":
// 					return wellPurposeTypeFormList;
// 			}
// 			break;
// 		case "well_status":
// 			switch (option) {
// 				case "well_status":
// 					return wellStatusFormList;
// 				case "well_status_type":
// 					return wellStatusTypeFormList;
// 			}
// 			break;
// 		case "well_project":
// 			return wellManagementFormList(record);
// 		case "phase":
// 			switch (option) {
// 				case "template":
// 					return phaseTemplateFormList(record);
// 				default:
// 					return phaseFormList(record);
// 			}
// 		case "task":
// 			return taskFormList;
// 		case "task-blocker":
// 			return taskBlockerFormList;
// 		case "deliverable":
// 			switch (option) {
// 				case "template":
// 					return deliverableTemplateFormList(record);
// 				default:
// 					return deliverableFormList;
// 			}
// 		case "well-project-details":
// 			return wellProjectDetailsFormList(record);
// 		case "well_programme_template":
// 			switch (option) {
// 				case "general":
// 					return wellProgrammeTemplateFormListGeneral(record);
// 				default:
// 					return wellProgrammeTemplateFormList(record, navigateHook);
// 			}

// 		default:
// 			return null;
// 	}
// };

// export const getLookupItem = (
// 	lookupKey: any,
// 	tenantKey: any = null,
// 	extraData: any = null,
// 	option: any = null
// ) => {
// 	return new Promise((resolve, reject) => {
// 		try {
// 			switch (lookupKey) {
// 				case "strategic_business_unit":
// 					getBusinessUnitList()
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "project_tags":
// 					Promise.all([getProjectTagList(), getTagGroupList()])
// 						.then((values: any) => {
// 							resolve({ tag: values[0], tagGroup: values[1] });
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "well_purpose":
// 					Promise.all([getWellPurposeList(), getWellPurposeTypeList()])
// 						.then((values: any) => {
// 							resolve({ purpose: values[0], type: values[1] });
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "well_status":
// 					Promise.all([getWellStatusList(), getWellStatusTypeList()])
// 						.then((values: any) => {
// 							resolve({ status: values[0], type: values[1] });
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "operating_office":
// 					Promise.all([getOperatingOfficeList(), countryList])
// 						.then((values: any) => {
// 							resolve({
// 								office: values[0],
// 								country: values[1],
// 							});
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "country":
// 					resolve(countryList);
// 					break;
// 				case "region":
// 					Promise.all([getRegionList(), countryList])
// 						.then((values: any) => {
// 							resolve({
// 								region: values[0],
// 								country: values[1],
// 							});
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "company":
// 					Promise.all([getCompanyList([], true), getRegionList(), countryList])
// 						.then((values: any) => {
// 							resolve({
// 								company: values[0],
// 								region: values[1],
// 								country: values[2],
// 							});
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "roles_permissions":
// 					getRolePermissionList()
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "operation_type":
// 					getOperationTypeList()
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "tenant":
// 					Promise.all([
// 						getAppRoles(),
// 						getTenantList(),
// 						getCompanyList([], true),
// 					])
// 						.then((values: any) => {
// 							let entraRoles = values[0]
// 								.map((tenantRole: any) => {
// 									if (tenantRole.isEnabled) {
// 										return tenantRole;
// 									}
// 								})
// 								.filter(Boolean)
// 								.filter((element: any) => element.value !== superAdminRole);

// 							let modifiedTenants = values[1] || [];
// 							let companies = values[2] || [];
// 							let tempData = [...entraRoles];
// 							let data = [];
// 							data = tempData.map((entraRole: any) => {
// 								let foundCompanies: any = null;
// 								let foundTenant = modifiedTenants.find(
// 									(element: any) => entraRole.id === element.uuid
// 								);

// 								if (foundTenant) {
// 									foundCompanies = companies
// 										.filter((element: any) =>
// 											foundTenant?.company_list?.includes(element.uuid)
// 										)
// 										.map((element: any) => element.name)
// 										.join(", ");
// 								}

// 								return {
// 									...entraRole,
// 									uuid: entraRole.id,
// 									role_key: entraRole.value,
// 									name: foundTenant?.name || entraRole?.displayName,
// 									company_list_name: foundCompanies,
// 									...foundTenant,
// 								};
// 							});
// 							resolve({
// 								tableData: data,
// 								lookupData: { company: companies, users: [] },
// 							});
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse?.response?.data?.error);
// 						});
// 					break;
// 				case "well_project":
// 					Promise.all([
// 						getWellPurposeList(),
// 						getWellStatusList(),
// 						getWellPurposeTypeList(),
// 						getWellStatusTypeList(),
// 						getOperationTypeList(),
// 						getCampaignBasedWellProjects(tenantKey, extraData),
// 						getWellProgrammeTemplateList(),
// 					])
// 						.then((values: any) => {
// 							resolve({
// 								tableData: values[5],
// 								lookupData: {
// 									well_purpose: values[0],
// 									well_status: values[1],
// 									well_purpose_type: values[2],
// 									well_status_type: values[3],
// 									operation_type: values[4],
// 									well_programme_template: values[6],
// 								},
// 							});
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse?.response?.data?.error);
// 						});
// 					break;
// 				case "phase":
// 					switch (option) {
// 						case "template":
// 							resolve([]);
// 							break;
// 						default:
// 							getPhasesAndTasks(tenantKey, extraData)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "deliverable":
// 					switch (option) {
// 						case "template":
// 							resolve([]);
// 							break;
// 						default:
// 							getDeliverables(tenantKey, extraData)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;

// 				case "well_programme_template":
// 					return getWellProgrammeTemplateList()
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse?.response?.data?.error);
// 						});
// 				default:
// 					reject(false);
// 			}
// 		} catch (error: any) {
// 			console.log(error);
// 		}
// 	});
// };

// export const updateLookupItem = (
// 	lookupKey: any,
// 	formOption: any = null,
// 	uuid: any,
// 	tenantKey: any = null,
// 	data: any,
// 	initialData: any,
// 	otherData: any = null,
// 	userData: any = null,
// 	formList: any = null
// ) => {
// 	return new Promise((resolve, reject) => {
// 		// console.log(initialData, data);
// 		try {
// 			data.modified_on = Date.now();
// 			switch (lookupKey) {
// 				case "strategic_business_unit":
// 					updateBusinessUnitItem(uuid, data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "project_tags":
// 					switch (formOption) {
// 						case "tag":
// 							updateProjectTagItem(uuid, data, initialData)
// 								.then((values: any) => {
// 									resolve(values);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 						case "tag_group":
// 							data.name = data.group.trim();
// 							updateTagGroupItem(uuid, data)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "well_purpose":
// 					switch (formOption) {
// 						case "well_purpose":
// 							data.name = data.group.trim();
// 							updateWellPurposeItem(uuid, data)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 						case "well_purpose_type":
// 							updateWellPurposeTypeItem(uuid, data, initialData)
// 								.then((values: any) => {
// 									resolve(values);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "well_status":
// 					switch (formOption) {
// 						case "well_status":
// 							data.name = data.group.trim();
// 							updateWellStatusItem(uuid, data)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 						case "well_status_type":
// 							updateWellStatusTypeItem(uuid, data, initialData)
// 								.then((values: any) => {
// 									resolve(values);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "operating_office":
// 					updateOperatingOfficeItem(uuid, data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "operation_type":
// 					updateOperationTypeItem(uuid, data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "region":
// 					updateRegionItem(uuid, data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "company":
// 					updateCompanyItem(uuid, data, initialData)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "roles_permissions":
// 					updateRolePermissionList(uuid, data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "tenant":
// 					updateTenantItem(uuid, data)
// 						.then((data: any) => {
// 							resolve(data);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "phase":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData._id };
// 							const pushData = {
// 								$set: {
// 									"phases.$[x].phase_name": data.phase_name,
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 								arrayFilters: [{ "x._id": uuid }],
// 							};

// 							const dupCheck = otherData.phases.some(
// 								(phase: any) =>
// 									phase.phase_name.toLowerCase() ===
// 										data.phase_name.toLowerCase() && phase._id != uuid
// 							);

// 							if (!dupCheck) {
// 								findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 									.then((value: any) => {
// 										Emitter.emit("updateTemplate", value);
// 										let foundPhase = value.phases.find(
// 											(phase: any) => phase._id === uuid
// 										);

// 										resolve(foundPhase);
// 									})
// 									.catch((errorResponse: any) => {
// 										reject(errorResponse);
// 									});
// 							} else {
// 								reject({
// 									code: 11000,
// 									keyPattern: {
// 										phase_name: null,
// 									},
// 								});
// 							}

// 							break;

// 						default:
// 							data.well_project_code = otherData.well_project_code;

// 							if ("task_data" in data) {
// 								if (data.task_data) {
// 									data.task_data.forEach((task: any, index: any) => {
// 										console.log(index, task);
// 										updateTaskItem(
// 											tenantKey,
// 											task._id,
// 											task,
// 											initialData,
// 											userData,
// 											formList
// 										)
// 											.then((value: any) => {
// 												resolve(value);
// 											})
// 											.catch((errorResponse: any) => {
// 												reject(errorResponse);
// 											});
// 									});
// 								}
// 							}

// 							updatePhaseItem(
// 								tenantKey,
// 								uuid,
// 								data,
// 								initialData,
// 								userData,
// 								formList
// 							)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});
// 							break;
// 					}
// 					break;
// 				case "task":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData.templateData._id };
// 							const pushData = {
// 								$set: {
// 									"phases.$[phase].tasks.$[task].task_name": data.task_name,
// 									"phases.$[phase].tasks.$[task].objective": data.objective,
// 									"phases.$[phase].tasks.$[task].notes": data.notes,
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 								arrayFilters: [
// 									{ "task._id": uuid },
// 									{ "phase._id": otherData.phase_id },
// 								],
// 							};

// 							findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 								.then((value: any) => {
// 									Emitter.emit("updateTemplate", value);
// 									let foundPhase = value.phases.find(
// 										(phase: any) => phase._id === otherData.phase_id
// 									);

// 									let foundTask = foundPhase.tasks.find(
// 										(task: any) => task._id === uuid
// 									);

// 									resolve(foundTask);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});
// 							break;

// 						default:
// 							updateTaskItem(
// 								tenantKey,
// 								uuid,
// 								data,
// 								initialData,
// 								userData,
// 								formList
// 							)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});
// 							break;
// 					}
// 					break;
// 				case "deliverable":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData.templateData._id };
// 							const pushData = {
// 								$set: {
// 									"phases.$[phase].tasks.$[task].deliverables.$[deliverable].deliverable_name":
// 										data.deliverable_name,
// 									"phases.$[phase].tasks.$[task].deliverables.$[deliverable].description":
// 										data.description,
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 								arrayFilters: [
// 									{ "task._id": otherData.task_id },
// 									{ "phase._id": otherData.phase_id },
// 									{ "deliverable._id": uuid },
// 								],
// 							};

// 							const dupCheck = otherData?.taskRecord?.deliverables?.some(
// 								(deliverable: any) =>
// 									deliverable.deliverable_name.toLowerCase() ===
// 										data.deliverable_name.toLowerCase() &&
// 									deliverable._id != uuid
// 							);

// 							if (!dupCheck) {
// 								findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 									.then((value: any) => {
// 										Emitter.emit("updateTemplate", value);
// 										let foundPhase = value.phases.find(
// 											(phase: any) => phase._id === otherData.phase_id
// 										);

// 										let foundTask = foundPhase.tasks.find(
// 											(task: any) => task._id === otherData.task_id
// 										);

// 										let foundDeliverable = foundTask.deliverables.find(
// 											(deliverable: any) => deliverable._id === uuid
// 										);

// 										resolve(foundDeliverable);
// 									})
// 									.catch((errorResponse: any) => {
// 										reject(errorResponse);
// 									});
// 							} else {
// 								reject({
// 									code: 11000,
// 									keyPattern: {
// 										deliverable_name: null,
// 									},
// 								});
// 							}

// 							break;
// 						default:
// 							updateDeliverableItem(
// 								tenantKey,
// 								uuid,
// 								data,
// 								initialData,
// 								userData,
// 								formList
// 							)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});
// 							break;
// 					}
// 					break;
// 				case "well_project":
// 					data.project_code = otherData?.project_code;
// 					updateWellProjectItem(
// 						tenantKey,
// 						uuid,
// 						data,
// 						initialData,
// 						userData,
// 						formList
// 					)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse);
// 						});
// 					break;
// 				case "well_programme_template":
// 					const filter = { _id: uuid };
// 					const options = { upsert: true, new: true };
// 					updateWellProgrammeTemplate(filter, data, options)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse?.response?.data?.error);
// 						});
// 					break;
// 			}
// 		} catch (error: any) {
// 			console.log(error);
// 		}
// 	});
// };

// export const addLookupItem = (
// 	lookupKey: any,
// 	formOption: any = null,
// 	data: any,
// 	otherData: any = null,
// 	tenantKey: any = null, // used for tenant specific data
// 	extraData: any = null,
// 	userData: any = null
// ) => {
// 	return new Promise((resolve, reject) => {
// 		try {
// 			data.uuid = v4();
// 			data.created_on = Date.now();
// 			data.modified_on = Date.now();
// 			switch (lookupKey) {
// 				case "strategic_business_unit":
// 					addBusinessUnitItem(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;

// 				case "project_tags":
// 					switch (formOption) {
// 						case "tag":
// 							addProjectTagItem(data)
// 								.then((values: any) => {
// 									resolve(values);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;

// 						case "tag_group":
// 							data.name = data.group.trim();
// 							addTagGroupItem(data)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;

// 				case "well_purpose":
// 					switch (formOption) {
// 						case "well_purpose_type":
// 							addWellPurposeTypeItem(data)
// 								.then((values: any) => {
// 									resolve(values);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;

// 						case "well_purpose":
// 							data.name = data.group.trim();
// 							addWellPurposeItem(data)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;

// 				case "well_status":
// 					switch (formOption) {
// 						case "well_status_type":
// 							addWellStatusTypeItem(data)
// 								.then((values: any) => {
// 									resolve(values);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;

// 						case "well_status":
// 							data.name = data.group.trim();
// 							addWellStatusItem(data)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;

// 				case "operating_office":
// 					addOperatingOfficeItem(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "operation_type":
// 					addOperationTypeItem(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "region":
// 					addRegionItem(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "company":
// 					addCompanyItem(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "roles_permissions":
// 					addRolePermissionList(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;

// 				case "tenant":
// 					addTenantItem(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "phase":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData._id };
// 							const pushData = {
// 								$push: {
// 									phases: data,
// 								},
// 							};
// 							let options = { upsert: true, new: true };

// 							const dupCheck = otherData.phases.some(
// 								(phase: any) =>
// 									phase.phase_name.toLowerCase() ===
// 									data.phase_name.toLowerCase()
// 							);
// 							if (!dupCheck) {
// 								findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 									.then((value: any) => {
// 										Emitter.emit("updateTemplate", value);
// 										resolve(value.phases[value.phases.length - 1]);
// 									})
// 									.catch((errorResponse: any) => {
// 										reject(errorResponse);
// 									});
// 							} else {
// 								reject({
// 									code: 11000,
// 									keyPattern: {
// 										phase_name: null,
// 									},
// 								});
// 							}
// 							break;
// 						default:
// 							data.well_project_code = otherData?.well_project_code;
// 							addPhaseItem(tenantKey, data, userData)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});
// 					}

// 					break;

// 				case "task":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData?.templateData?._id };
// 							const pushData = {
// 								$push: {
// 									"phases.$[phase].tasks": data,
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 								arrayFilters: [{ "phase._id": otherData?.phase_id }],
// 							};

// 							findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 								.then((value: any) => {
// 									Emitter.emit("updateTemplate", value);
// 									const findPhaseIndex = value.phases.findIndex(
// 										(element: any) => element._id === otherData?.phase_id
// 									);
// 									if (findPhaseIndex != -1) {
// 										resolve(
// 											value.phases[findPhaseIndex].tasks[
// 												value.phases[findPhaseIndex].tasks.length - 1
// 											]
// 										);
// 									} else {
// 										reject(false);
// 									}
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});

// 							break;

// 						default:
// 							data.project_code = otherData?.project_code;
// 							data.well_project_code = otherData?.well_project_code;
// 							data.phase_id = otherData?.phase_id;
// 							addTaskItem(tenantKey, data, userData)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});
// 							break;
// 					}

// 					break;
// 				case "deliverable":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData?.templateData?._id };
// 							const pushData = {
// 								$push: {
// 									"phases.$[phase].tasks.$[task].deliverables": data,
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 								arrayFilters: [
// 									{ "phase._id": otherData?.phase_id },
// 									{ "task._id": otherData?.task_id },
// 								],
// 							};

// 							const dupCheck = otherData?.taskRecord?.deliverables?.some(
// 								(deliverable: any) =>
// 									deliverable.deliverable_name.toLowerCase() ===
// 									data.deliverable_name.toLowerCase()
// 							);

// 							if (!dupCheck) {
// 								findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 									.then((value: any) => {
// 										Emitter.emit("updateTemplate", value);
// 										const foundPhase = value.phases.find(
// 											(phase: any) => phase._id === otherData?.phase_id
// 										);
// 										if (foundPhase) {
// 											const findTaskIndex = foundPhase.tasks.findIndex(
// 												(task: any) => task._id === otherData?.task_id
// 											);
// 											if (findTaskIndex != -1) {
// 												resolve(
// 													foundPhase.tasks[findTaskIndex].deliverables[
// 														foundPhase.tasks[findTaskIndex].deliverables
// 															.length - 1
// 													]
// 												);
// 											} else {
// 												reject(false);
// 											}
// 										} else {
// 											reject(false);
// 										}
// 									})
// 									.catch((errorResponse: any) => {
// 										reject(errorResponse);
// 									});
// 							} else {
// 								reject({
// 									code: 11000,
// 									keyPattern: {
// 										deliverable_name: null,
// 									},
// 								});
// 							}
// 							break;

// 						default:
// 							data.project_code = otherData?.project_code;
// 							data.well_project_code = otherData?.well_project_code;
// 							data.task_id = otherData?.task_id;
// 							addDeliverableItem(tenantKey, data, userData)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});
// 							break;
// 					}
// 					break;
// 				case "well_project":
// 					data.project_code = otherData?.project_code;
// 					data.status = "New";
// 					data.well_project_code = CryptoJS.lib.WordArray.random(8).toString(
// 						CryptoJS.enc.Hex
// 					);
// 					if (tenantKey) {
// 						addWellProjectItem(tenantKey, data, userData)
// 							.then((value: any) => {
// 								resolve(value);
// 							})
// 							.catch((errorResponse: any) => {
// 								reject(errorResponse);
// 							});
// 					} else {
// 						console.log("tenantKey is missing");
// 					}
// 					break;

// 				case "well_programme_template":
// 					addWellProgrammeTemplate(data)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse?.response?.data?.error);
// 						});
// 					break;
// 			}
// 		} catch (error: any) {
// 			console.log(error);
// 		}
// 	});
// };

// export const deleteLookupItem = (
// 	lookupKey: any,
// 	formOption: any = null,
// 	uuid: any,
// 	tenantKey: any = null,
// 	projectRelatedData: any = null,
// 	otherData: any = null,
// 	userData: any = null
// ) => {
// 	return new Promise((resolve, reject) => {
// 		try {
// 			switch (lookupKey) {
// 				case "strategic_business_unit":
// 					deleteBusinessUnitItem(uuid)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "project_tags":
// 					switch (formOption) {
// 						case "tag":
// 							deleteProjectTagItem(uuid)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;

// 						case "tag_group":
// 							deleteTagGroupItem(uuid)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "well_purpose":
// 					switch (formOption) {
// 						case "well_purpose_type":
// 							deleteWellPurposeTypeItem(uuid)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;

// 						case "well_purpose":
// 							deleteWellPurposeItem(uuid)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;

// 				case "well_status":
// 					switch (formOption) {
// 						case "well_status_type":
// 							deleteWellStatusTypeItem(uuid)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;

// 						case "well_status":
// 							deleteWellStatusItem(uuid)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "operating_office":
// 					deleteOperatingOfficeItem(uuid)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "operation_type":
// 					deleteOperationTypeItem(uuid)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "region":
// 					deleteRegionItem(uuid)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "company":
// 					deleteCompanyItem(uuid)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "roles_permissions":
// 					deleteRolePermissionList(uuid)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse.response.data.error);
// 						});
// 					break;
// 				case "well_project":
// 					// need project code data
// 					deleteWellProjectItem(tenantKey, uuid, projectRelatedData, userData)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse?.response?.data.error);
// 						});
// 					break;
// 				case "phase":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData._id };
// 							const pushData = {
// 								$pull: {
// 									phases: { _id: uuid },
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 							};

// 							findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 								.then((value: any) => {
// 									Emitter.emit("updateTemplate", value);
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});

// 							break;
// 						default:
// 							// need project code and well code data
// 							deletePhaseItem(tenantKey, uuid, projectRelatedData, userData)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "task":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData.templateData._id };
// 							const pushData = {
// 								$pull: {
// 									"phases.$[phase].tasks": { _id: uuid },
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 								arrayFilters: [{ "phase._id": otherData.phase_id }],
// 							};

// 							findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 								.then((value: any) => {
// 									Emitter.emit("updateTemplate", value);
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});

// 							break;
// 						default:
// 							// need project code and well code data
// 							deleteTaskItem(tenantKey, uuid, projectRelatedData, userData)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "deliverable":
// 					switch (formOption) {
// 						case "template":
// 							const filter = { _id: otherData.templateData._id };
// 							const pushData = {
// 								$pull: {
// 									"phases.$[phase].tasks.$[task].deliverables": { _id: uuid },
// 								},
// 							};
// 							let options = {
// 								upsert: true,
// 								new: true,
// 								arrayFilters: [
// 									{ "phase._id": otherData.phase_id },
// 									{ "task._id": otherData.task_id },
// 								],
// 							};

// 							findAndUpdateWellProgrammeTemplate(filter, pushData, options)
// 								.then((value: any) => {
// 									Emitter.emit("updateTemplate", value);
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse);
// 								});

// 							break;
// 						default:
// 							// need project code, well code, task code data
// 							deleteDeliverableItem(
// 								tenantKey,
// 								uuid,
// 								projectRelatedData,
// 								userData
// 							)
// 								.then((value: any) => {
// 									resolve(value);
// 								})
// 								.catch((errorResponse: any) => {
// 									reject(errorResponse.response.data.error);
// 								});
// 							break;
// 					}
// 					break;
// 				case "well_programme_template":
// 					deleteWellProgrammeTemplate(uuid)
// 						.then((value: any) => {
// 							resolve(value);
// 						})
// 						.catch((errorResponse: any) => {
// 							reject(errorResponse?.response?.data?.error);
// 						});
// 					break;
// 			}
// 		} catch (error: any) {
// 			console.log(error);
// 		}
// 	});
// };

export const getAddButtonProp = (key: any) => {
	switch (key) {
		case "project_tags":
			return [
				{ key: "tag", label: "Tag" },
				{ key: "tag_group", label: "Tag group" },
			];
		case "well_purpose":
			return [
				{ key: "well_purpose", label: "Purpose" },
				{ key: "well_purpose_type", label: "Purpose type" },
			];
		case "well_status":
			return [
				{ key: "well_status", label: "Status" },
				{ key: "well_status_type", label: "Status type" },
			];
		default:
			return null;
	}
};

export const multiCheckItems: any = (key: any) => {
	switch (key) {
		case "roles_permissions":
			return rolesPermissionList;
		default:
			return [];
	}
};

// const businessUnitColumnKeys = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Strategic business unit ID"
// 					columnKey="id"
// 					key={key + "id"}
// 				/>
// 			),
// 			dataIndex: "id",
// 			key: "id",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Strategic business unit"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),

// 			dataIndex: "name",
// 			key: "name",
// 			width: 200,
// 		},
// 	];
// };

// const businessUnitFormList: any = [
// 	{
// 		label: "Strategic business unit ID",
// 		name: "id",
// 		required: true,
// 		type: "input",
// 		validatorFunctions: [
// 			(rule: any, value: any) => {
// 				if (value && value.includes(" ")) {
// 					return Promise.reject(new Error("Spacing is not allowed."));
// 				}
// 				return Promise.resolve();
// 			},
// 		],
// 	},
// 	{
// 		label: "Strategic business unit name",
// 		name: "name",
// 		required: true,
// 		type: "input",
// 	},
// ];

// const projectTagsColumnKeys = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Group"
// 					columnKey="group"
// 					key={key + "group"}
// 				/>
// 			),
// 			dataIndex: "group",
// 			key: "tag_group",
// 			isActive: true,
// 			isGroup: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Tag"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "tag",
// 			isActive: true,
// 			tag: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Description"
// 					columnKey="description"
// 					key={key + "description"}
// 				/>
// 			),
// 			dataIndex: "description",
// 			key: "description",
// 			width: 200,
// 		},
// 		{
// 			title: "No. of projects",
// 			sorter: (a: any, b: any) => a.projects_count - b.projects_count,
// 			showSorterTooltip: false,
// 			dataIndex: "projects_count",
// 			key: "projects_count",
// 			width: 200,
// 		},
// 	];
// };

// const projectTagsFormList: any = [
// 	{
// 		label: "Tag name",
// 		name: "name",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		type: "flex",
// 		children: [
// 			{
// 				label: "Color",
// 				name: "color",
// 				type: "colorPicker",
// 				required: true,
// 			},
// 			{
// 				notForm: true,
// 				label: "No. of projects",
// 				name: "projects_count",
// 				type: "disabled",
// 			},
// 		],
// 	},
// 	{
// 		label: "Tag group",
// 		name: "group_uuid",
// 		required: true,
// 		type: "select",
// 		extraKey: "tagGroup",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		type: "textArea",
// 	},
// ];

// const projectTagGroupFormList: any = [
// 	{
// 		label: "Tag group name",
// 		name: "group",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "List of tags",
// 		name: "tags",
// 		type: "select",
// 		extraKey: "tag",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 		mode: "tags",
// 	},
// ];

// const operatingOfficeColumnKeys = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Operating office"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "office_name",
// 			key: "name",
// 			isActive: true,
// 			width: 200,
// 		},
// 	];
// };

// const operatingOfficeFormList: any = [
// 	{
// 		label: "Operating office",
// 		name: "name",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "Country",
// 		name: "country",
// 		required: true,
// 		type: "select",
// 		optionLabel: "name",
// 		optionKey: "alpha-3",
// 		extraKey: "country",
// 		// relayFrom: "region",
// 	},
// ];

// const countryColumnKeys = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Country code"
// 					columnKey="alpha-3"
// 					key={key + "alpha-3"}
// 				/>
// 			),
// 			// width: 150,
// 			dataIndex: "alpha-3",
// 			key: "alpha-3",
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Country"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "name",
// 			width: 200,
// 		},
// 	];
// };

// const regionColumnList = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Region code"
// 					columnKey="code"
// 					key={key + "code"}
// 				/>
// 			),
// 			// width: 150,
// 			dataIndex: "code",
// 			key: "code",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Region"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "name",
// 			width: 200,
// 		},
// 	];
// };

// const regionFormList: any = [
// 	{
// 		type: "flex",
// 		children: [
// 			{
// 				label: "Region Code",
// 				name: "code",
// 				required: true,
// 				type: "input",
// 				// width: 18,
// 			},
// 			{
// 				label: "Region",
// 				name: "name",
// 				required: true,
// 				type: "input",
// 				// width: 25,
// 			},
// 		],
// 	},
// 	{
// 		label: "List of Countries",
// 		type: "divider",
// 	},

// 	{
// 		label: "Countries",
// 		name: "countries",
// 		required: true,
// 		type: "select-multiple",
// 		extraKey: "country",
// 		optionLabel: "name",
// 		optionKey: "alpha-3",
// 		list: true,
// 	},
// ];

// const companyColumnList = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Company"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "name",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Domain"
// 					columnKey="domain"
// 					key={key + "domain"}
// 				/>
// 			),
// 			dataIndex: "domain",
// 			key: "domain",
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Region"
// 					columnKey="region_name"
// 					key={key + "region_name"}
// 				/>
// 			),
// 			dataIndex: "region_name",
// 			key: "region",
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Country"
// 					columnKey="country_name"
// 					key={key + "country_name"}
// 				/>
// 			),
// 			dataIndex: "country_name",
// 			key: "country",
// 			width: 200,
// 		},
// 	];
// };
// const companyFormList: any = [
// 	{
// 		label: "Company name",
// 		name: "name",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "Parent Company",
// 		name: "parent",
// 		required: false,
// 		type: "disabled",
// 	},
// 	{
// 		label: "Domain",
// 		name: "domain",
// 		required: false,
// 		type: "input",
// 	},
// 	{
// 		label: "Region",
// 		name: "region",
// 		optionLabel: "name",
// 		optionKey: "code",
// 		extraKey: "region",
// 		relayIndexKey: "countries",
// 		relayLookupKey: "alpha-3",
// 		relayTo: "country",
// 		required: false,
// 		type: "select",
// 	},
// 	{
// 		label: "Country",
// 		name: "country",
// 		required: false,
// 		type: "select",
// 		optionLabel: "name",
// 		optionKey: "alpha-3",
// 		extraKey: "country",
// 		relayFrom: "region",
// 	},
// 	{
// 		label: "Subsidiaries (If any)",
// 		type: "divider",
// 	},

// 	{
// 		label: "Company name",
// 		name: "subsidiaries",
// 		required: false,
// 		type: "select-multiple",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 		extraKey: "company",
// 		list: true,
// 	},
// ];

// const rolesColumnList = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Role"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "Role",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Description"
// 					columnKey="description"
// 					key={key + "description"}
// 				/>
// 			),
// 			dataIndex: "description",
// 			key: "description",
// 			width: 200,
// 		},
// 	];
// };

// const rolesFormList: any = [
// 	{
// 		label: "Role",
// 		name: "name",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		required: false,
// 		type: "textArea",
// 	},

// 	{
// 		label: "Permissions",
// 		type: "divider",
// 	},
// 	{ type: "rolesPermissions", name: "permissions" },
// ];

// const rolesConfigurationFormList: any = [
// 	{
// 		label: "Project manager",
// 		name: "project_manager",
// 		type: "select",
// 		optionLabel: "label",
// 		optionKey: "value",
// 		extraKey: "project_managers",
// 		required: true,
// 	},
// 	{
// 		label: "Team members",
// 		type: "divider",
// 	},
// 	{
// 		name: "role_assignments",
// 		type: "role-dynamic-form",
// 		gap: "2%",
// 		pivotKey: "role",
// 		extraKey: "roles",
// 		optionKey: "name",
// 		removeItemMessage:
// 			"Remove team role? This will also remove the role from associated deliverables.",
// 		children: [
// 			{
// 				label: "Role",
// 				uniqueKeyName: "role_id", //Add this to store both id and name
// 				name: "role",
// 				type: "select",
// 				optionLabel: "name",
// 				optionKey: "_id",
// 				extraKey: "roles",
// 				filteredOn: "projects",
// 			},
// 			{
// 				label: "Assigned to",
// 				name: "appointee",
// 				type: "select",
// 				mode: "multiple",
// 				optionLabel: "mail",
// 				optionKey: "mail",
// 				extraKey: "users",
// 				required: true,
// 			},
// 		],
// 	},
// ];

const rolesPermissionList = [
	{
		label: "Project visibility",
		description:
			"Control the visibility of projects within the organization's hierarchy",
		key: "project-visibility",
		keys: [
			{
				label: "View own projects",
				key: "Projects.OwnRead",
			},
			{
				label: "View projects under assignment clients",
				key: "Projects.ClientRead",
			},
			{
				label: "View ALL projects within tenant",
				key: "Projects.TenantRead",
			},
		],
	},
	{
		label: "Manage projects",
		description: "Control various aspects of project management",
		keys: [
			{
				label: "Add new projects",
				key: "ProjectManagement.Create",
			},
			{
				label: "View projects",
				key: "ProjectManagement.Read",
			},
			{ label: "Update projects", key: "ProjectManagement.Update" },
			{ label: "Delete projects", key: "ProjectManagement.Delete" },
		],
	},
];

// const projectDetailsFormList: any = [
// 	{
// 		label: "Project title",
// 		name: "project_name",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "Project type",
// 		name: "project_type",
// 		required: true,
// 		type: "select",
// 	},
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		children: [
// 			{
// 				label: "Strategic business unit",
// 				name: "business_unit",
// 				required: true,
// 				type: "select",
// 				width: 18,
// 			},
// 			{
// 				label: "Client",
// 				name: "client",
// 				required: true,
// 				type: "select",
// 				width: 25,
// 			},
// 		],
// 	},
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		children: [
// 			{
// 				label: "Region",
// 				name: "region",
// 				required: true,
// 				type: "select",
// 				width: 18,
// 			},
// 			{
// 				label: "Country",
// 				name: "country",
// 				required: true,
// 				type: "select",
// 				width: 25,
// 			},
// 		],
// 	},
// 	{
// 		label: "Project type",
// 		name: "project_type",
// 		required: true,
// 		type: "select",
// 	},
// 	{
// 		label: "Tags",
// 		name: "project_tags",
// 		required: true,
// 		type: "select",
// 		mode: "multiple",
// 	},
// ];

// const tenantManagmentColumnList = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Tenant"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "Tenant",
// 			isActive: true,
// 			width: 200,
// 		},
// 		// {
// 		// 	title: (
// 		// 		<SearchButton
// 		// 			handleSearch={handleSearch}
// 		// 			headerName="Companies"
// 		// 			columnKey="company_list_name"
// 		// 			key={key + "company_list_name"}
// 		// 		/>
// 		// 	),
// 		// 	dataIndex: "company_list_name",
// 		// 	key: "Companies",
// 		// },
// 	];
// };

// const tenantManagementFormList: any = [
// 	{
// 		label: "Tenant logo",
// 		name: "logo",
// 		required: false,
// 		type: "image",
// 		removeMessage:
// 			"Remove logo? It will be replaced with tenant's initials instead.",
// 		updateImage: (uuid: any, data: any) => {
// 			return new Promise((resolve: any, reject: any) => {
// 				console.log(uuid, data);
// 				data.logo = data.image;
// 				data.role_key = data.value;
// 				updateTenantItem(uuid, data)
// 					.then((data: any) => {
// 						resolve(data);
// 						Emitter.emit("tenantUpdated", null);
// 					})
// 					.catch((error: any) => {
// 						reject(error);
// 					});
// 			});
// 		},
// 		removeImage: (uuid: any) => {
// 			return new Promise((resolve: any, reject: any) => {
// 				updateTenantItem(uuid, { logo: null })
// 					.then((data: any) => {
// 						Emitter.emit("tenantUpdated", null);
// 						resolve(data);
// 					})
// 					.catch((error: any) => {
// 						reject(error);
// 					});
// 			});
// 		},
// 	},
// 	{
// 		label: "Key",
// 		name: "role_key",
// 		required: true,
// 		type: "disabled",
// 	},
// 	{
// 		label: "Name",
// 		name: "name",
// 		required: true,
// 		type: "input",
// 	},
// 	// {
// 	// 	label: "List of companies (If any)",
// 	// 	type: "divider",
// 	// },
// 	// {
// 	// 	label: "Company",
// 	// 	name: "company_list",
// 	// 	required: false,
// 	// 	type: "select-multiple",
// 	// 	optionLabel: "name",
// 	// 	optionKey: "uuid",
// 	// 	extraKey: "company",
// 	// 	list: true,
// 	// },
// 	{
// 		label: "List of project managers (If any)",
// 		type: "divider",
// 	},
// 	{
// 		label: "Project manager",
// 		name: "project_managers",
// 		required: false,
// 		type: "select-multiple",
// 		optionLabel: "mail",
// 		optionKey: "mail",
// 		extraKey: "users",
// 		list: true,
// 	},
// ];

// const wellPurposeColumnList = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Purpose"
// 					columnKey="group"
// 					key={key + "group"}
// 				/>
// 			),
// 			dataIndex: "group",
// 			key: "well_purpose",
// 			isActive: true,
// 			isGroup: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Type"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "well_purpose_type",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Description"
// 					columnKey="description"
// 					key={key + "description"}
// 				/>
// 			),
// 			dataIndex: "description",
// 			key: "description",
// 			width: 200,
// 		},
// 		{
// 			title: "No. of wells",
// 			sorter: (a: any, b: any) => a.wells_count - b.wells_count,
// 			showSorterTooltip: false,
// 			dataIndex: "wells_count",
// 			key: "wells_count",
// 			width: 200,
// 		},
// 	];
// };

// const wellStatusColumnList = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Status"
// 					columnKey="group"
// 					key={key + "group"}
// 				/>
// 			),
// 			dataIndex: "group",
// 			key: "well_status",
// 			isActive: true,
// 			isGroup: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Type"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "well_status_type",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Description"
// 					columnKey="description"
// 					key={key + "description"}
// 				/>
// 			),
// 			dataIndex: "description",
// 			key: "description",
// 			width: 200,
// 		},
// 		{
// 			title: "No. of wells",
// 			sorter: (a: any, b: any) => a.wells_count - b.wells_count,
// 			showSorterTooltip: false,
// 			dataIndex: "wells_count",
// 			key: "wells_count",
// 			width: 200,
// 		},
// 	];
// };

// const wellPurposeFormList: any = [
// 	{
// 		label: "Purpose",
// 		name: "group",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "List of purpose types",
// 		type: "divider",
// 	},
// 	{
// 		label: "Purpose type",
// 		name: "types",
// 		type: "select-multiple",
// 		extraKey: "type",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 		list: true,
// 	},
// ];

// const wellPurposeTypeFormList: any = [
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		// gridTemplateColumns: "2fr 1fr",
// 		children: [
// 			{
// 				label: "Purpose type",
// 				name: "name",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				notForm: true,
// 				label: "No. of wells",
// 				name: "wells_count",
// 				type: "disabled",
// 			},
// 		],
// 	},
// 	{
// 		label: "Purpose",
// 		name: "group_uuid",
// 		required: true,
// 		type: "select",
// 		extraKey: "purpose",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		type: "textArea",
// 	},
// ];

// const wellStatusFormList: any = [
// 	{
// 		label: "Status",
// 		name: "group",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "List of status types",
// 		type: "divider",
// 	},
// 	{
// 		label: "Status type",
// 		name: "types",
// 		required: false,
// 		type: "select-multiple",
// 		extraKey: "type",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 		list: true,
// 	},
// ];

// const wellStatusTypeFormList: any = [
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		// gridTemplateColumns: "2fr 1fr",
// 		children: [
// 			{
// 				label: "Status type",
// 				name: "name",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				notForm: true,
// 				label: "No. of wells",
// 				name: "wells_count",
// 				type: "disabled",
// 			},
// 		],
// 	},
// 	{
// 		label: "Status",
// 		name: "group_uuid",
// 		required: true,
// 		type: "select",
// 		extraKey: "status",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		type: "textArea",
// 	},
// ];

// const helpCenterFormList: any = [
// 	{
// 		label: "Name",
// 		name: "name",
// 		required: true,
// 		type: "input",
// 		disabled: true,
// 	},
// 	{
// 		type: "grid",
// 		gap: "2%",
// 		children: [
// 			{
// 				label: "Email",
// 				name: "email",
// 				required: true,
// 				type: "input",
// 				disabled: true,
// 			},
// 			{
// 				label: "Phone",
// 				name: "phone_number",
// 				// required: true,
// 				type: "phone_number",
// 				disabled: true,
// 			},
// 		],
// 	},
// 	{
// 		label: "Subject",
// 		name: "subject",
// 		required: true,
// 		type: "input",
// 		count: 50,
// 	},
// 	{
// 		label: "Message",
// 		name: "message",
// 		required: true,
// 		type: "textArea",
// 		count: 300,
// 		resize: false,
// 		size: { minRows: 5, maxRows: 6 },
// 	},
// 	{
// 		name: "terms",
// 		required: true,
// 		type: "checkbox",
// 		text: "I consent  to having Elemental Energies store my submitted information so they can respond to my inquiry.",
// 	},
// ];

// const wellManagementColumnList = (
// 	key: any,
// 	handleSearch: any,
// 	allData: any
// ) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Well"
// 					columnKey="well_project_name"
// 					key={key + "well_project_name"}
// 				/>
// 			),
// 			dataIndex: "well_project_name",
// 			key: "Well",
// 			width: 200,
// 			isActive: true,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Operation type"
// 					columnKey="operation_type_name"
// 					key={key + "operation_type"}
// 				/>
// 			),
// 			dataIndex: "operation_type",
// 			key: "Operation type",
// 			width: 200,
// 			render: (text: any, record: any) => {
// 				return setLookupData(allData, record, "operation_type", null, "table");
// 			},
// 		},
// 		{
// 			title: "Planned start date",
// 			dataIndex: "planned_start_date",
// 			key: "Planned start date",
// 			width: 200,
// 			sorter: (a: any, b: any) => {
// 				const dateA = a.planned_start_date
// 					? new Date(a.planned_start_date)
// 					: null;
// 				const dateB = b.planned_start_date
// 					? new Date(b.planned_start_date)
// 					: null;

// 				if (dateA && dateB) {
// 					return dateA.getTime() - dateB.getTime();
// 				} else if (dateA) {
// 					return 1;
// 				} else if (dateB) {
// 					return -1;
// 				} else {
// 					return 0;
// 				}
// 			},
// 			showSorterTooltip: false,
// 			render: (text: any) => {
// 				if (text) {
// 					var date = new Date(text);
// 					return date.toLocaleDateString("en-GB", {
// 						year: "numeric",
// 						month: "short",
// 						day: "numeric",
// 					});
// 				} else {
// 					return "";
// 				}
// 			},
// 		},
// 		{
// 			title: "Est. duration (days)",
// 			dataIndex: "estimated_duration_days",
// 			key: "Est. duration (days)",
// 			width: 200,
// 			sorter: (a: any, b: any) => {
// 				const durationA =
// 					typeof a.estimated_duration_days === "number"
// 						? a.estimated_duration_days
// 						: 0;
// 				const durationB =
// 					typeof b.estimated_duration_days === "number"
// 						? b.estimated_duration_days
// 						: 0;

// 				return durationA - durationB;
// 			},
// 			showSorterTooltip: false,
// 		},
// 		{
// 			title: "Expected end date",
// 			dataIndex: "expected_end_date",
// 			key: "Expected end date",
// 			width: 200,
// 			sorter: (a: any, b: any) => {
// 				let dateA: any = null;
// 				let dateB: any = null;
// 				if (a.planned_start_date && a?.estimated_duration_days) {
// 					var date = new Date(a.planned_start_date);
// 					date.setDate(date.getDate() + a?.estimated_duration_days);
// 					dateA = new Date(date);
// 				}
// 				if (b.planned_start_date && b?.estimated_duration_days) {
// 					var date = new Date(b.planned_start_date);
// 					date.setDate(date.getDate() + b?.estimated_duration_days);
// 					dateB = new Date(date);
// 				}

// 				if (dateA && dateB) {
// 					return dateA.getTime() - dateB.getTime();
// 				} else if (dateA) {
// 					return 1;
// 				} else if (dateB) {
// 					return -1;
// 				} else {
// 					return 0;
// 				}
// 			},
// 			showSorterTooltip: false,
// 			render: (text: any, record: any) => {
// 				// Add planned_start_date with estimated_duration_days
// 				if (record.planned_start_date && record?.estimated_duration_days) {
// 					var date = new Date(record.planned_start_date);
// 					date.setDate(date.getDate() + record?.estimated_duration_days);
// 					// change the date format to "1 Mar 2024"
// 					return date.toLocaleDateString("en-GB", {
// 						year: "numeric",
// 						month: "short",
// 						day: "numeric",
// 					});
// 				} else {
// 					return "";
// 				}
// 			},
// 		},
// 	];
// };

// const wellManagementFormList: any = (record: any) => [
// 	{
// 		label: "Name",
// 		name: "well_project_name",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		label: "Planned start date",
// 		name: "planned_start_date",
// 		type: "datepicker",
// 	},
// 	{
// 		label: "Est. duration (days)",
// 		name: "estimated_duration_days",
// 		type: "counter",
// 		min: 0,
// 		precision: 1,
// 		tooltip:
// 			"This field will automatically round your input to one decimal place.",
// 	},
// 	{
// 		label: "Operation type",
// 		name: "operation_type",
// 		type: "select",
// 		extraKey: "operation_type",
// 		optionLabel: "name",
// 		optionKey: "uuid",
// 	},
// 	{
// 		label: "Project comment",
// 		name: "well_project_comments",
// 		type: "textArea",
// 	},
// 	{
// 		label: "Well details",
// 		type: "divider",
// 	},
// 	{
// 		label: "Location",
// 		name: "location",
// 		required: true,
// 		type: "radio",
// 		options: [
// 			{ label: "Offshore", value: "offshore" },
// 			{ label: "Onshore", value: "onshore" },
// 		],
// 		initialValue: "onshore",
// 	},
// 	{
// 		type: "latlong",
// 		name: "latlong",
// 		required: true,
// 	},
// 	{
// 		type: "flex",
// 		rowGap: "20px",
// 		gap: "2%",
// 		children: [
// 			{
// 				label: "Block",
// 				name: "block",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				label: "Field",
// 				name: "field",
// 				required: true,
// 				type: "input",
// 			},
// 		],
// 	},
// 	{
// 		type: "flex",
// 		rowGap: "20px",
// 		gap: "2%",
// 		children: [
// 			{
// 				label: "Platform",
// 				name: "platform",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				label: "Water depth (meters)",
// 				name: "water_depth",
// 				required: true,
// 				type: "counter",
// 				precision: 0,
// 				min: 0,
// 				tooltip:
// 					"This field will automatically round your input to a whole number.",
// 			},
// 		],
// 	},
// 	{
// 		label: "License",
// 		name: "license",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		type: "flex",
// 		rowGap: "20px",
// 		gap: "2%",
// 		children: [
// 			{
// 				label: "Purpose",
// 				name: "well_purpose",
// 				required: true,
// 				type: "select",
// 				extraKey: "well_purpose",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayIndexKey: "types",
// 				relayLookupKey: "uuid",
// 				relayTo: "well_purpose_type",
// 				width: "200px",
// 			},
// 			{
// 				label: "Purpose type",
// 				name: "well_purpose_type",
// 				type: "select",
// 				extraKey: "well_purpose_type",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayFrom: "well_purpose",
// 				width: "200px",
// 			},
// 		],
// 	},
// 	{
// 		type: "flex",
// 		rowGap: "20px",
// 		gap: "2%",
// 		children: [
// 			{
// 				label: "Status",
// 				name: "well_status",
// 				required: true,
// 				type: "select",
// 				extraKey: "well_status",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayIndexKey: "types",
// 				relayLookupKey: "uuid",
// 				relayTo: "well_status_type",
// 				width: "200px",
// 			},
// 			{
// 				label: "Status type",
// 				name: "well_status_type",
// 				type: "select",
// 				extraKey: "well_status_type",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayFrom: "well_status",
// 				width: "200px",
// 			},
// 		],
// 	},
// 	{
// 		label: "Well comment",
// 		name: "well_comments",
// 		type: "textArea",
// 	},
// 	{
// 		label: "Programme details",
// 		type: "divider",
// 	},
// 	{
// 		label: "Project template",
// 		name: "project_template",
// 		// required: true,
// 		type: "select",
// 		extraKey: "well_programme_template",
// 		optionLabel: "name",
// 		optionKey: "_id",
// 		disabled: record != null,
// 	},
// ];

// const wellProjectDetailsFormList: any = (record: any) => [
// 	{
// 		label: "Name",
// 		name: "well_project_name",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		type: "flex",
// 		rowGap: "20px",
// 		gap: "2%",
// 		children: [
// 			{
// 				label: "Planned start date",
// 				name: "planned_start_date",
// 				type: "datepicker",
// 			},
// 			{
// 				label: "Est. duration (days)",
// 				name: "estimated_duration_days",
// 				type: "counter",
// 				min: 0,
// 				precision: 1,
// 				tooltip:
// 					"This field will automatically round your input to one decimal place.",
// 			},
// 			{
// 				label: "Operation type",
// 				name: "operation_type",
// 				type: "select",
// 				extraKey: "operation_type",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 			},
// 		],
// 	},
// 	{
// 		label: "Project comment",
// 		name: "well_project_comments",
// 		type: "textArea",
// 	},
// 	{
// 		label: "Well details",
// 		type: "divider",
// 	},
// 	{
// 		type: "flex",
// 		rowGap: "20px",
// 		gap: "2%",
// 		gridTemplateColumns: "1fr 2fr",
// 		children: [
// 			{
// 				label: "Location",
// 				name: "location",
// 				required: true,
// 				type: "radio",
// 				options: [
// 					{ label: "Offshore", value: "offshore" },
// 					{ label: "Onshore", value: "onshore" },
// 				],
// 				initialValue: "onshore",
// 			},
// 			{
// 				type: "latlong",
// 				name: "latlong",
// 				required: true,
// 			},
// 		],
// 	},
// 	{
// 		label: "License",
// 		name: "license",
// 		required: true,
// 		type: "input",
// 	},
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		children: [
// 			{
// 				label: "Block",
// 				name: "block",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				label: "Field",
// 				name: "field",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				label: "Platform",
// 				name: "platform",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				label: "Water depth (meters)",
// 				name: "water_depth",
// 				required: true,
// 				type: "counter",
// 				precision: 0,
// 				min: 0,
// 				tooltip:
// 					"This field will automatically round your input to a whole number.",
// 			},
// 		],
// 	},
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		children: [
// 			{
// 				label: "Purpose",
// 				name: "well_purpose",
// 				required: true,
// 				type: "select",
// 				extraKey: "well_purpose",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayIndexKey: "types",
// 				relayLookupKey: "uuid",
// 				relayTo: "well_purpose_type",
// 				width: "200px",
// 			},
// 			{
// 				label: "Purpose type",
// 				name: "well_purpose_type",
// 				type: "select",
// 				extraKey: "well_purpose_type",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayFrom: "well_purpose",
// 				width: "200px",
// 			},
// 		],
// 	},
// 	{
// 		type: "flex",
// 		children: [
// 			{
// 				label: "Status",
// 				name: "well_status",
// 				required: true,
// 				type: "select",
// 				extraKey: "well_status",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayIndexKey: "types",
// 				relayLookupKey: "uuid",
// 				relayTo: "well_status_type",
// 				width: "200px",
// 			},
// 			{
// 				label: "Status type",
// 				name: "well_status_type",
// 				type: "select",
// 				extraKey: "well_status_type",
// 				optionLabel: "name",
// 				optionKey: "uuid",
// 				relayFrom: "well_status",
// 				width: "200px",
// 			},
// 		],
// 	},
// 	{
// 		label: "Well comment",
// 		name: "well_comments",
// 		type: "textArea",
// 	},
// 	{
// 		label: "Programme details",
// 		type: "divider",
// 	},
// 	{
// 		label: "Project template",
// 		name: "project_template",
// 		// required: true,
// 		type: "select",
// 		extraKey: "well_programme_template",
// 		optionLabel: "name",
// 		optionKey: "_id",
// 		disabled: record != null,
// 	},
// ];

// const operationTypeColumnKeys: any = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Operation type"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "Operation type",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Description"
// 					columnKey="description"
// 					key={key + "description"}
// 				/>
// 			),
// 			dataIndex: "description",
// 			key: "Description",
// 			width: 200,
// 		},
// 		{
// 			title: "No. of wells",
// 			dataIndex: "wellCount",
// 			sorter: (a: any, b: any) => a.wellCount - b.wellCount,
// 			showSorterTooltip: false,
// 			key: "wellCount",
// 			width: 200,
// 		},
// 	];
// };

// const operationTypeFormList: any = [
// 	{
// 		type: "flex",
// 		gap: "2%",
// 		children: [
// 			{
// 				label: "Operation type",
// 				name: "name",
// 				required: true,
// 				type: "input",
// 				// width: 30,
// 			},
// 			{
// 				label: "No. of wells",
// 				name: "wellCount",
// 				// required: true,
// 				type: "disabled",
// 				// width: 10,
// 			},
// 		],
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		required: false,
// 		type: "textArea",
// 	},
// ];

// const phaseColumnList: any = (key: any, handleSearch: any, extraData: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Phase"
// 					columnKey="phase_name"
// 					key={key + "phase_name"}
// 				/>
// 			),
// 			dataIndex: "phase_name",
// 			key: "name",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: "Planned start date",
// 			sorter: (a: any, b: any) => {
// 				const dateA = a.planned_start_date
// 					? new Date(a.planned_start_date)
// 					: null;
// 				const dateB = b.planned_start_date
// 					? new Date(b.planned_start_date)
// 					: null;

// 				if (dateA && dateB) {
// 					return dateA.getTime() - dateB.getTime();
// 				} else if (dateA) {
// 					return 1;
// 				} else if (dateB) {
// 					return -1;
// 				} else {
// 					return 0;
// 				}
// 			},
// 			showSorterTooltip: false,
// 			width: 200,
// 			dataIndex: "planned_start_date",
// 			key: "planned_start_date",
// 			render: (text: any) => {
// 				if (text) {
// 					var date = new Date(text);
// 					return date.toLocaleDateString("en-GB", {
// 						year: "numeric",
// 						month: "short",
// 						day: "numeric",
// 					});
// 				} else {
// 					return "";
// 				}
// 			},
// 		},
// 		{
// 			title: "Est. duration (days)",
// 			dataIndex: "estimated_duration_days",
// 			width: 200,
// 			sorter: (a: any, b: any) =>
// 				a.estimated_duration_days - b.estimated_duration_days,
// 			showSorterTooltip: false,
// 			key: "estimated_duration_days",
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Tasks"
// 					columnKey="task_list"
// 					key={key + "task_list"}
// 				/>
// 			),
// 			dataIndex: "task_list",
// 			key: "task_list",
// 			width: 200,
// 			render: (text: any, record: any) => {
// 				return (
// 					<div>
// 						{extraData?.task_data?.[record?._id]?.map((task: any) => {
// 							return (
// 								<>
// 									<a
// 										onClick={() => {
// 											Emitter.emit("taskModalOpen", { ...record, task });
// 										}}
// 									>
// 										&#x2022; {task.task_name}
// 									</a>
// 									<br />
// 								</>
// 							);
// 						})}
// 					</div>
// 				);
// 			},
// 		},
// 	];
// };

// const phaseFormList: any = (record: any) => {
// 	return [
// 		{
// 			label: "Phase title",
// 			name: "phase_name",
// 			required: true,
// 			type: "input",
// 		},
// 		{
// 			type: "flex",
// 			rowGap: "20px",
// 			gap: "2%",
// 			children: [
// 				{
// 					label: "Planned start date",
// 					name: "planned_start_date",
// 					type: "datepicker",
// 				},
// 				{
// 					label: "Est. duration (days)",
// 					name: "estimated_duration_days",
// 					type: "counter",
// 					min: 0,
// 					precision: 1,
// 					tooltip:
// 						"This field will automatically round your input to one decimal place.",
// 				},
// 			],
// 		},
// 		{
// 			label: "List of tasks",
// 			type: "divider",
// 		},
// 		{
// 			label: "+ Add",
// 			margin: "-15px auto 0 0",
// 			padding: "0px 0px 5px 0px",
// 			type: "button",
// 			buttonType: "link",
// 			disableKey: "activeRowKey",
// 			popOverMessage: "Will be enabled after saving phase details",
// 			popOverMargin: "30px auto 0 0",
// 			function: () => {
// 				Emitter.emit("taskModalOpen", record);
// 			},
// 		},
// 		{
// 			label: "Task",
// 			type: "reorder-list",
// 			name: "task_data",
// 			prefixKey: "",
// 			labelKey: "task_name",
// 		},
// 	];
// };
// const taskFormList: any = [
// 	{
// 		type: "flex",
// 		gap: "20px",
// 		// gridTemplateColumns: "0.7fr 1fr",
// 		children: [
// 			{
// 				label: "Task number",
// 				name: "task_number",
// 				type: "input",
// 				tooltip: "Task number is auto generated.",
// 				disabled: true,
// 				required: true,
// 			},
// 			{
// 				label: "Task title",
// 				name: "task_name",
// 				type: "input",
// 				required: true,
// 			},
// 		],
// 	},
// 	{
// 		label: "Objective",
// 		name: "objective",
// 		required: false,
// 		type: "textArea",
// 	},
// 	{
// 		label: "Notes",
// 		name: "notes",
// 		required: false,
// 		type: "textArea",
// 	},
// ];

// const taskBlockerFormList: any = [
// 	{
// 		label: "Blocker",
// 		name: "blocker",
// 		type: "textArea",
// 		required: true,
// 	},
// 	{
// 		label: "Plan",
// 		name: "plan",
// 		type: "textArea",
// 		required: false,
// 	},
// ];

// const deliverableColumnList: any = (
// 	key: any,
// 	handleSearch: any,
// 	extraData: any
// ) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Deliverable"
// 					columnKey="deliverable_name"
// 					key={key + "deliverable_name"}
// 				/>
// 			),
// 			dataIndex: "deliverable_name",
// 			key: "Deliverable name",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Responsible"
// 					columnKey="responsible_roles_names"
// 					key={key + "responsible_roles"}
// 				/>
// 			),
// 			dataIndex: "responsible_roles_names",
// 			key: "Responsible",
// 			render: (_: any, record: any) => {
// 				let content: any = setLookupData(
// 					extraData,
// 					record,
// 					"roles",
// 					"responsible_roles",
// 					"table"
// 				);
// 				if (content?.length > 0 && Array.isArray(content)) {
// 					return content.map((roles: any) => {
// 						return (
// 							<Tag
// 								style={{
// 									whiteSpace: "normal",
// 									wordWrap: "break-word",
// 									borderRadius: "2px",
// 									border: "1px solid rgba(255,255,255,0.3)",
// 									background: "rgba(113, 113, 113, 0.50)",
// 									color: "rgba(255, 255, 255, 0.85",
// 									margin: "2px",
// 								}}
// 								key={roles._id}
// 							>
// 								{roles?.name}
// 							</Tag>
// 						);
// 					});
// 				} else {
// 					return "";
// 				}
// 			},
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Accountable"
// 					columnKey="accountable_roles_names"
// 					key={key + "accountable_roles"}
// 				/>
// 			),
// 			dataIndex: "accountable_roles_names",
// 			key: "Accountable",
// 			render: (text: any, record: any) => {
// 				let content: any = setLookupData(
// 					extraData,
// 					record,
// 					"roles",
// 					"accountable_roles",
// 					"table"
// 				);
// 				if (content?.length > 0 && Array.isArray(content)) {
// 					return content.map((roles: any) => {
// 						return (
// 							<Tag
// 								style={{
// 									whiteSpace: "normal",
// 									wordWrap: "break-word",
// 									borderRadius: "2px",
// 									border: "1px solid rgba(255,255,255,0.3)",
// 									background: "rgba(113, 113, 113, 0.50)",
// 									color: "rgba(255, 255, 255, 0.85",
// 									margin: "2px",
// 								}}
// 								key={roles._id}
// 							>
// 								{roles?.name}
// 							</Tag>
// 						);
// 					});
// 				} else {
// 					return "";
// 				}
// 			},
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Consulted"
// 					columnKey="consultants_names"
// 					key={key + "consultants"}
// 				/>
// 			),
// 			dataIndex: "consultants_names",
// 			key: "Consult",
// 			render: (text: any, record: any) => {
// 				let content: any = setLookupData(
// 					extraData,
// 					record,
// 					"roles",
// 					"consulted",
// 					"table"
// 				);
// 				if (content?.length > 0 && Array.isArray(content)) {
// 					return content.map((roles: any) => {
// 						return (
// 							<Tag
// 								style={{
// 									whiteSpace: "normal",
// 									wordWrap: "break-word",
// 									borderRadius: "2px",
// 									border: "1px solid rgba(255,255,255,0.3)",
// 									background: "rgba(113, 113, 113, 0.50)",
// 									color: "rgba(255, 255, 255, 0.85",
// 									margin: "2px",
// 								}}
// 								key={roles._id}
// 							>
// 								{roles?.name}
// 							</Tag>
// 						);
// 					});
// 				} else {
// 					return "";
// 				}
// 			},
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Informed"
// 					columnKey="informed_parties_names"
// 					key={key + "informed_parties"}
// 				/>
// 			),
// 			dataIndex: "informed_parties_names",
// 			key: "Inform",
// 			render: (text: any, record: any) => {
// 				let content: any = setLookupData(
// 					extraData,
// 					record,
// 					"roles",
// 					"informed_parties",
// 					"table"
// 				);
// 				if (content?.length > 0 && Array.isArray(content)) {
// 					return content.map((roles: any) => {
// 						return (
// 							<Tag
// 								style={{
// 									whiteSpace: "normal",
// 									wordWrap: "break-word",
// 									borderRadius: "2px",
// 									border: "1px solid rgba(255,255,255,0.3)",
// 									background: "rgba(113, 113, 113, 0.50)",
// 									color: "rgba(255, 255, 255, 0.85",
// 									margin: "2px",
// 								}}
// 								key={roles._id}
// 							>
// 								{roles?.name}
// 							</Tag>
// 						);
// 					});
// 				} else {
// 					return "";
// 				}
// 			},
// 			width: 200,
// 		},
// 	];
// };

// const deliverableFormList: any = [
// 	{
// 		label: "Deliverable title",
// 		name: "deliverable_name",
// 		type: "input",
// 		required: true,
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		type: "textArea",
// 		required: false,
// 	},
// 	{
// 		type: "flex",
// 		gap: "20px",
// 		children: [
// 			{
// 				label: "Responsible role(s)",
// 				name: "responsible_roles",
// 				type: "select",
// 				mode: "multiple",
// 				required: true,
// 				extraKey: "role_assignments",
// 				optionLabel: "role",
// 				optionKey: "role_id",
// 				width: "200px",
// 			},
// 			{
// 				label: "Accountable role(s)",
// 				name: "accountable_roles",
// 				type: "select",
// 				mode: "multiple",
// 				required: false,
// 				extraKey: "role_assignments",
// 				optionLabel: "role",
// 				optionKey: "role_id",
// 				width: "200px",
// 			},
// 		],
// 	},
// 	{
// 		type: "flex",
// 		gap: "20px",
// 		children: [
// 			{
// 				label: "Consulted",
// 				name: "consulted",
// 				type: "select",
// 				mode: "multiple",
// 				required: false,
// 				extraKey: "role_assignments",
// 				optionLabel: "role",
// 				optionKey: "role_id",
// 				width: "200px",
// 			},
// 			{
// 				label: "Informed party(s)",
// 				name: "informed_parties",
// 				type: "select",
// 				mode: "multiple",
// 				required: false,
// 				extraKey: "role_assignments",
// 				optionLabel: "role",
// 				optionKey: "role_id",
// 				width: "200px",
// 			},
// 		],
// 	},
// 	{
// 		label: "Document links",
// 		type: "divider",
// 	},
// 	{
// 		name: "document_links",
// 		type: "*", // "temporary-dynamic",
// 		gap: "2%",
// 		removeItemMessage:
// 			"Remove this document link? You can still add it back later.",
// 		children: [
// 			{
// 				label: "Title",
// 				name: "title",
// 				type: "input",
// 			},
// 			{
// 				label: "Link",
// 				name: "link",
// 				type: "input",
// 				required: true,
// 				tooltip:
// 					"Enter the document title first to activate the link field for adding the document's URL.",
// 			},
// 		],
// 	},
// ];

// const wellProgrammeTemplateColumnList = (key: any, handleSearch: any) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Programme"
// 					columnKey="name"
// 					key={key + "name"}
// 				/>
// 			),
// 			dataIndex: "name",
// 			key: "Programme",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Description"
// 					columnKey="description"
// 					key={key + "description"}
// 				/>
// 			),
// 			dataIndex: "description",
// 			key: "Description",
// 			width: 200,
// 		},
// 		{
// 			title: "No. of wells",
// 			dataIndex: "wellCount",
// 			sorter: (a: any, b: any) => a.wellCount - b.wellCount,
// 			showSorterTooltip: false,
// 			key: "wellCount",
// 			width: 200,
// 		},
// 	];
// };
// const wellProgrammeTemplateFormList = (record: any, navigateHook: any) => [
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		// gridTemplateColumns: "2fr 1fr",
// 		children: [
// 			{
// 				label: "Programme title",
// 				name: "name",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				notForm: true,
// 				label: "No. of wells",
// 				name: "wells_count",
// 				type: "disabled",
// 			},
// 		],
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		type: "textArea",
// 	},
// 	{
// 		label: "Overview",
// 		type: "divider",
// 	},
// 	{
// 		label: <>{GetAntIcon("settings")} Programme settings</>,
// 		margin: "-15px auto 0 0",
// 		padding: "0px 0px 5px 0px",
// 		type: "button",
// 		buttonType: "link",
// 		disableKey: "activeRowKey",
// 		popOverMessage: "Will be enabled after saving template details.",
// 		popOverMargin: "30px auto 0 0",
// 		function: () => {
// 			navigateHook("programme-settings", { state: record });
// 		},
// 	},
// 	// {
// 	// 	label: "Task",
// 	// 	type: "list",
// 	// 	name: "task_data",
// 	// 	prefixKey: "",
// 	// 	labelKey: "task_name",
// 	// },
// 	{
// 		type: "boxlist",
// 		data: record?.phases,
// 		columns: ["Phase", "Tasks"],
// 		identifierColumn1: "phase_name",
// 		identifierColumn2: "tasks.task_name",
// 	},
// ];

// const wellProgrammeTemplateFormListGeneral = (record: any) => [
// 	{
// 		type: "flex",
// 		// gap: "2%",
// 		// gridTemplateColumns: "2fr 1fr",
// 		children: [
// 			{
// 				label: "Programme title",
// 				name: "name",
// 				required: true,
// 				type: "input",
// 			},
// 			{
// 				notForm: true,
// 				label: "No. of wells",
// 				name: "wells_count",
// 				type: "disabled",
// 			},
// 		],
// 	},
// 	{
// 		label: "Description",
// 		name: "description",
// 		type: "textArea",
// 	},
// ];

// const phaseTemplateColumnList: any = (
// 	key: any,
// 	handleSearch: any,
// 	extraData: any
// ) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Phase"
// 					columnKey="phase_name"
// 					key={key + "phase_name"}
// 				/>
// 			),
// 			dataIndex: "phase_name",
// 			key: "name",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Tasks"
// 					columnKey="task_list"
// 					key={key + "task_list"}
// 				/>
// 			),
// 			dataIndex: "task_list",
// 			key: "task_list",
// 			width: 200,
// 			render: (text: any, record: any) => {
// 				return (
// 					<div>
// 						{extraData?.task_data?.[record?._id]?.map((task: any) => {
// 							return (
// 								<>
// 									<a
// 										onClick={() => {
// 											Emitter.emit("taskModalOpen", { ...record, task });
// 										}}
// 									>
// 										&#x2022; {task.task_name}
// 									</a>
// 									<br />
// 								</>
// 							);
// 						})}
// 					</div>
// 				);
// 			},
// 		},
// 	];
// };

// const phaseTemplateFormList: any = (record: any) => {
// 	return [
// 		{
// 			label: "Phase title",
// 			name: "phase_name",
// 			required: true,
// 			type: "input",
// 		},
// 		{
// 			label: "List of tasks",
// 			type: "divider",
// 		},
// 		{
// 			label: "+ Add",
// 			margin: "-15px auto 0 0",
// 			padding: "0px 0px 5px 0px",
// 			type: "button",
// 			buttonType: "link",
// 			disableKey: "activeRowKey",
// 			popOverMessage: "Will be enabled after saving phase details",
// 			popOverMargin: "30px auto 0 0",
// 			function: () => {
// 				Emitter.emit("taskModalOpen", record);
// 			},
// 		},
// 		{
// 			label: "Task",
// 			type: "reorder-list",
// 			name: "task_data",
// 			prefixKey: "",
// 			labelKey: "task_name",
// 		},
// 	];
// };

// const deliverableTemplateFormList: any = (record: any) => {
// 	return [
// 		{
// 			label: "Deliverable title",
// 			name: "deliverable_name",
// 			type: "input",
// 			required: true,
// 		},
// 		{
// 			label: "Description",
// 			name: "description",
// 			type: "textArea",
// 			required: false,
// 		},
// 	];
// };

// const deliverableTemplateColumnList: any = (
// 	key: any,
// 	handleSearch: any,
// 	extraData: any
// ) => {
// 	return [
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Deliverable"
// 					columnKey="deliverable_name"
// 					key={key + "deliverable_name"}
// 				/>
// 			),
// 			dataIndex: "deliverable_name",
// 			key: "Deliverable name",
// 			isActive: true,
// 			width: 200,
// 		},
// 		{
// 			title: (
// 				<SearchButton
// 					handleSearch={handleSearch}
// 					headerName="Description"
// 					columnKey="description"
// 					key={key + "description"}
// 				/>
// 			),
// 			dataIndex: "description",
// 			key: "Description",
// 			isActive: true,
// 			width: 200,
// 		},
// 	];
// };
