import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "graphql";

export const getAppRoles = (roles: any = null, superRole: any = null) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}/getAppRoles`, {
				params: {
					filter: {
						roles,
						superRole,
					},
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getAppUsers = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}/getAppUsers`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getTenantSpecificUsers = (appRole: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}/getAppRoleUsers`, {
				params: {
					filter: { appRole },
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
