import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Popover, Progress, Space, Tag, Tooltip, Typography } from "antd";
import Emitter from "../../utils/emitter";
import { useNavigate } from "react-router";
import { GetAntIcon } from "../../utils/ant_icons";
import { countryList } from "../../utils/countryCodes";
import { checkPrivilege } from "../../utils/utils";
import LookupMissingTooltip from "../LookupMissingTooltip";
import { HomeContext } from "../../containers/Home";
import useHandleContextMenu from "../../hooks/useHandleContextMenu";

const { Text } = Typography;
const statusList = ["New", "In progress", "Resolved"];
const statusCount: any = {
	New: 0,
	"In progress": 30,
	Resolved: 100,
};
const infoKeyList = [
	"project_code",
	"client",
	"region",
	"country",
	"business_unit",
	// "current_phase",
	// "rig_type",
	// "planned_well_duration",
	// "water_depth",
];
const infoIcons: any = {
	project_code: GetAntIcon("number"),
	rig_type: GetAntIcon("project"),
	region: GetAntIcon("compass"),
	country: GetAntIcon("map"),
	business_unit: GetAntIcon("deploymentunit"),
	client: GetAntIcon("thunderbolt"),
	current_phase: GetAntIcon("layout"),
};

function ProjectCards(props: any) {
	const context: any = useContext(HomeContext);
	const navigate = useNavigate();
	const { handleContextMenu } = useHandleContextMenu();
	const setProjectContent = (currentProject: any, infoKey: any) => {
		let content: any = <LookupMissingTooltip userRole={props.userRole} />;
		if (currentProject[infoKey]) {
			if (infoKey === "region") {
				let found = props?.regions?.find((element: any) => {
					return element.uuid === currentProject[infoKey];
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "country") {
				let found = props.countries?.find((element: any) => {
					return currentProject.country === element["alpha-3"];
				});
				if (found) {
					content = found.name;
				} else {
					content = "Aberdeen";
				}
			} else if (infoKey == "rig_type" && !currentProject[infoKey]) {
				content = "Drilling";
			} else if (infoKey == "business_unit") {
				let found = props?.businessUnits.find((element: any) => {
					return element.uuid == currentProject.business_unit;
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey === "client") {
				let found = props.clients.find((element: any) => {
					return element.uuid === currentProject[infoKey];
				});
				if (found) {
					content = found.name;
				}
			} else if (infoKey == "project_tags") {
				let tags = currentProject.project_tags.map((uuid: any) => {
					let found = props?.projectTags.find((element: any) => {
						return element.uuid == uuid;
					});
					return found;
				});

				if (tags.length === 0) {
					return <span>-</span>;
				}

				return tags.map((tag: any) => {
					if (tag) {
						return (
							<Tag
								color={tag.color}
								key={tag.name}
								style={{
									cursor: "default",
									whiteSpace: "normal",
									wordWrap: "break-word",
								}}
							>
								{tag.name}
							</Tag>
						);
					}
				});
			} else {
				content = currentProject[infoKey];
			}
		}
		return content;
	};
	const renderProjectDetails = (project: any) => {
		const renderCardBody = (code: any) => {
			if (props.activeProjects.includes(code)) {
				return (
					<>
						{/* Project Status */}
						<div style={{ width: "fit-content", fontSize: "12px" }}>
							{renderStatus(project.status)}
						</div>
						{/* Project detail body */}
						<div className="project-grid-container">
							{infoKeyList.map((infoKey: any) => {
								return (
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "8px",
											color: "rgba(255,255,255,0.45)",
											fontSize: "12px",
											minWidth: "100%",
											width: "0",
										}}
									>
										<span>{infoIcons[infoKey]}</span>
										<Text
											title=""
											style={
												infoKey == "country"
													? {
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
													  }
													: {}
											}
											ellipsis={{
												// onEllipsis: () => {},
												tooltip: (
													<span>{setProjectContent(project, infoKey)}</span>
												),
											}}
										>
											<span
												style={
													infoKey == "country"
														? {
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
																fontSize: "12px",
														  }
														: {
																fontSize: "12px",
														  }
												}
											>
												{setProjectContent(project, infoKey)}
											</span>
										</Text>
									</div>
								);
							})}
						</div>
						{project?.project_tags?.length > 0 && (
							<div
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									gap: "8px",
									color: "rgba(255,255,255,0.45)",
									fontSize: "12px",
									minWidth: "100%",
									width: "0",
								}}
							>
								<span>{GetAntIcon("tags")}</span>
								<span
									style={{
										display: "flex",
										flexWrap: "wrap",
										rowGap: "10px",
										justifyContent: "flex-start",
										alignItems: "flex-start",
									}}
								>
									{setProjectContent(project, "project_tags")}
								</span>
							</div>
						)}
					</>
				);
			} else {
				return (
					<>
						{/* Project country */}
						<div
							style={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "8px",
								color: "rgba(255,255,255,0.45)",
								fontSize: "12px",
							}}
						>
							<span>{GetAntIcon("map")}</span>

							<Text
								title=""
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
								ellipsis={{
									// onEllipsis: () => {},
									tooltip: <span>{setProjectContent(project, "country")}</span>,
								}}
							>
								<span
									style={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										fontSize: "12px",
									}}
								>
									{setProjectContent(project, "country")}
								</span>
							</Text>
						</div>
					</>
				);
			}
		};
		const renderStatus = (projectStatus: String) => {
			switch (projectStatus) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>In Progress</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-Resolved">
							<span>Resolved</span>
						</div>
					);
			}
		};

		return (
			<Space
				className="project-card-container"
				styles={{ item: { width: "100%" } }}
			>
				{/* Project Title */}
				<div>
					<Text
						title=""
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
						className={
							// checkPrivilege(props.userRole, ["project-manager"])
							// 	? "project-title-clickable"
							// 	: ""
							"project-title-clickable"
						}
						ellipsis={{
							// onEllipsis: () => {},
							tooltip: <span>{project.project_name}</span>,
						}}
					>
						<span
							onClick={() => {
								// if (checkPrivilege(props.userRole, ["project-manager"])) {
								Emitter.emit("projectName", project.project_name);
								localStorage.setItem("currentProject", project.project_code);
								context.setCurrentProject(project);
								navigate(`/projects/${project.project_code}`, {
									replace: false,
									state: { ...project },
								});
								// }
							}}
							onContextMenu={(e: any) => {
								const location = `${window.location.href}/${project.project_code}`;
								handleContextMenu(e, { link: location });
							}}
						>
							{project.project_name}
						</span>{" "}
					</Text>
				</div>
				{renderCardBody(project.project_code)}
				{/* Project progress bar */}
				<Progress
					style={{ width: "100%" }}
					percent={Math.round(project.progress) || 0}
				/>
			</Space>
		);
	};
	return (
		<div
			style={{ height: "min-content" }}
			className="project-card"
			onClick={() => {
				let currentProjects = [...props.activeProjects];
				let foundIndex = props.activeProjects.findIndex(
					(element: any) => element === props.project.project_code
				);

				if (foundIndex < 0) {
					currentProjects.push(props.project.project_code);
				} else {
					currentProjects.splice(foundIndex, 1);
				}
				props.setActiveProjects(currentProjects);
			}}
		>
			{renderProjectDetails(props.project)}
		</div>
	);
}

export default ProjectCards;
