import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "status";

export const getWellStatusList = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}`, {
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getWellStatusItem = (uuid: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						uuid: uuid,
					},
				}
			})
			.then((_data: any) => {
				if (_data?.data && _data.data.length) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const addWellStatusItem = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/${apiURL}`, data, AuthHeader)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const updateWellStatusItem = (uuid: any, data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${apiURL}`,
				{
					filter: { uuid: uuid },
					data: data,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const deleteWellStatusItem = (uuid: any) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${url}/${apiURL}`, {
				params: {
					filter: {
						uuid: uuid,
					},
				},
				...AuthHeader,
			})
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
