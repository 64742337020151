import { useState, useEffect, useRef, MutableRefObject } from 'react';

interface Dimensions {
    width: number;
    height: number;
}

const useContainerDimensions = (): [MutableRefObject<HTMLDivElement | null>, Dimensions] => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });

    useEffect(() => {
        console.log('size changed', ref.current)
        const element = ref.current;
        if (!element) return;

        const updateDimensions = () => {
            const { width, height } = element.getBoundingClientRect();
            setDimensions({ width, height });
        };

        const resizeObserver = new ResizeObserver(() => {
            updateDimensions();
        });

        resizeObserver.observe(element);
        updateDimensions();

        return () => {
            resizeObserver.disconnect();
        };

    }, [ref.current]);

    return [ref, dimensions];
};

export default useContainerDimensions;
