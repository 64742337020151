import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Popconfirm, Tag, Tooltip } from "antd";
import CustomForm from "../CustomForm";
import Table, { ColumnsType } from "antd/es/table";
import { HomeContext } from "../../containers/Home";
import { useForm } from "antd/es/form/Form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "../FormButtonSave";
import Emitter from "../../utils/emitter";
import { excludedMark, statusIcon } from "../../utils/statusIcon";
import { getCrudaClass } from "../../utils/lookup_list";

function TaskConfigurationModal(props: any) {
	const data: any = props.data;
	const context: any = useContext(HomeContext);
	const [formRef] = useForm();

	const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
	const [dataTable, setDataTable] = useState<any[]>([]);

	const [formReset, setFormReset] = useState<boolean>(true);
	const [updated, setUpdated] = useState<boolean>(false);
	const [expandedRowKeys, setExpandRowKey] = useState<any>([]);
	const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
	const [tableHeight, setTableHeight] = useState<any>(null);
	const [ready, setReady] = useState<any>(false);
	const [initialForm, setInitialForm] = useState<any>(null);

	const [modalVisible, setModalVisible] = useState(false);

	const [cancelPopover, setCancelPopover] = useState<any>(false);
	const [deletePopover, setDeletePopover] = useState<any>(false);

	const [tempRecord, setTempRecord] = useState<any>(null);
	const [searchRecord, setSearchRecord] = useState<any>([]);
	const [relatedTask, setRelatedTask] = useState<any>(props.allCampaignTasks);
	const [phaseTasks, setPhaseTasks] = useState<any>(null);

	const open = props.open;
	const setOpen = props.setOpen;
	const phaseID = props.phaseID;
	const taskRecord = props.taskRecord;
	const activeRowKey = props.activeRowKey;
	const handleModalClose = props.handleModalClose;
	const handleFormUpdate = props.handleFormUpdate;

	const record = props.record;
	const setDeliverableRecord = props.setDeliverableRecord;
	const setDeliverableModalOpen = props.setDeliverableModalOpen;
	const extraData = props.extraData;

	useEffect(() => {
		window.addEventListener("resize", () => {
			calculateTableHeight();
		});

		calculateTableHeight();
	}, []);

	useEffect(() => {
		if (searchRecord && tempRecord) {
			let recordIndex = searchRecord.findIndex((item: any) => {
				return item.columnKey === tempRecord.columnKey;
			});
			if (recordIndex !== -1) {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record[recordIndex] = tempRecord;
					setSearchRecord(_record);
				} else {
					let _record = [...searchRecord];
					_record.splice(recordIndex, 1);
					setSearchRecord(_record);
				}
			} else {
				if (tempRecord.value != "") {
					let _record = [...searchRecord];
					_record.push(tempRecord);
					setSearchRecord(_record);
				}
			}
		}
	}, [tempRecord]);

	useEffect(() => {
		if (searchRecord?.length > 0) {
			let data: any = [];

			data = [...dataTable];
			let filteredData = data
				?.map((element: any) => {
					let bool = searchRecord.every((item: any) => {
						if (item.extraKey) {
						}
						if (Array.isArray(element[item.columnKey])) {
							return element[item.columnKey].some((arrayItem: any) =>
								arrayItem.toLowerCase().includes(item.value.toLowerCase())
							);
						} else {
							return element[item.columnKey]
								?.toLowerCase()
								.includes(item.value.toLowerCase());
						}
					});
					if (bool) return element;
				})
				.filter((element: any) => element !== null && element !== undefined);
			setFilteredDataTable(filteredData);
		} else {
			setFilteredDataTable(null);
		}
	}, [searchRecord]);

	const handleSearch = (record: any) => {
		setTempRecord(record);
	};
	const handleKeyClick = async (record: any, element: any, key: any) => {
		try {
			setDeliverableRecord({ ...record, task_disabled: taskRecord.disabled });
			setDeliverableModalOpen(true);
		} catch {}
	};

	const handleTagClick = async (record: any, element: any, key: any) => {
		if (element.key != activeRowKey) {
			try {
				await context.handlePageChange(key);
				resetForm(record);
			} catch {}
		}
	};

	useEffect(() => {
		setReady(false);
		window.addEventListener("resize", () => {
			calculateTableHeight();
		});
		Emitter.emit("loading", true);
		getCrudaClass("deliverable").then((crudaClass: any) => {
			let columns: any = crudaClass.getColumnList(
				"deliverable",
				handleSearch,
				context.allData,
				extraData,
				props?.option
			);

			//Fetch Deliverables data based on taskRecord._id
			if (activeRowKey)
				crudaClass
					.getLookupItem(
						"deliverable",
						context.currentTenantKey,
						activeRowKey,
						props?.option
					)
					.then((data: any) => {
						if (data) {
							if (columns) {
								if (props?.option == "template") {
									data = props?.taskRecord.deliverables;
								} else {
									let role_assignments_data = extraData.role_assignments.map(
										(element: any) => {
											return { label: element.role, value: element.role_id };
										}
									);
									data = data.map((item: any) => {
										// Add roles' name for easy search
										let assigned_to_names =
											role_assignments_data
												.filter((roles: any) => {
													return item.assigned_to.includes(roles.value);
												})
												?.map((obj: any) => obj.label) || [];
										let reviewed_by_names =
											role_assignments_data
												.filter((roles: any) => {
													return item.reviewed_by.includes(roles.value);
												})
												?.map((obj: any) => obj.label) || [];

										return {
											...item,
											assigned_to_names,
											reviewed_by_names,
										};
									});
								}
								const renderedColumns = columns?.map((element: any) => {
									if (element.isActive == true && !element.render) {
										element.render = (text: any, record: any) => {
											const id = record.uuid || record.group_uuid || record._id;
											if (text) {
												if (element.tag) {
													return (
														<Tag
															color={record.color}
															key={record.name + record.group}
															onClick={() => {
																handleTagClick(record, element, id);
															}}
															className="table-clickable-text"
															style={{
																whiteSpace: "normal",
																wordWrap: "break-word",
															}}
														>
															{text}
														</Tag>
													);
												} else {
													return (
														<Tooltip
															title={
																taskRecord.disabled || record.disabled
																	? "Deliverable is excluded. Click to view details"
																	: ""
															}
														>
															<a
																onClick={() => {
																	handleKeyClick(record, element, id);
																}}
																style={{
																	display: "flex",
																	gap: "10px",
																	filter: record?.disabled
																		? "grayscale(1)"
																		: "none",
																}}
																className="table-clickable-text"
															>
																{record?.disabled && excludedMark()}
																{props?.option != "template" &&
																	statusIcon(record.status)}{" "}
																{text}
															</a>
														</Tooltip>
													);
												}
											}
										};
										return element;
									}
									return element;
								});
								setTableColumns(renderedColumns);
							}
							setDataTable(data);
						} else {
							setDataTable([]);
						}
					})
					.catch((error: any) => {
						console.log(error);
					})
					.finally(() => {});

			if (taskRecord) {
				resetForm(taskRecord);
			} else if (relatedTask) {
				let taskNumber: any = 1;
				if (relatedTask?.length > 0) {
					// 'relatedTask' is already sorted by task_number in ascending order.
					// Parse the task_number to float and check if the task_number is numeric.
					// If it is numeric, increment the task_number by 1. Else, use the next task_number.
					for (let i = relatedTask.length - 1; i >= 0; i--) {
						const lastTaskNumber = parseFloat(relatedTask[i].task_number);
						if (!isNaN(lastTaskNumber)) {
							taskNumber = lastTaskNumber + 1;
							break;
						}
					}
				}
				formRef.setFieldsValue({ task_number: taskNumber });
			}
		});

		getCrudaClass("task").then((crudaClass: any) => {
			if (!activeRowKey) {
				crudaClass
					.getPhaseTasks(context.currentTenantKey, phaseID)
					.then((phaseData: any) => {
						console.log("setting phase task data", phaseData);
						setPhaseTasks(phaseData);
					});
			}
		});

		Emitter.emit("loading", false);
	}, [updated, context.currentTenantKey, props?.taskRecord, relatedTask]);

	//Emitter Handlers
	useEffect(() => {
		Emitter.on("forceUpdateDeliverable", (payload: any) => {
			let data = [...dataTable];
			let recordIndex = dataTable.findIndex(
				(data: any) => data._id === payload._id
			);
			if (recordIndex != -1) {
				data[recordIndex] = { ...payload };
				setDataTable(data);
			}
		});

		return () => {
			Emitter.remove("forceUpdateDeliverable");
		};
	}, [activeRowKey, dataTable]);

	const calculateTableHeight = () => {
		const table: any = document.getElementById("sub-table-container");
		if (table) {
			setTableHeight(table?.clientHeight - 65 - 64);
		}
	};

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			setInitialForm(values);
		}
	};

	const handleFormCancel = () => {
		setOpen(false);
		setCancelPopover(false);
		context?.handleFormUpdating(false, activeRowKey);
		handleModalClose();
		// setSideContainerOpen(false);
		// setFilteredExtraData(null);
		// setActiveRowKey(null);
		// setActiveGroupKey(null);
		// setActiveRecord(null);
		resetForm();
	};

	const handleModalOk = (values: any) => {
		// updateLookupItem(
		// 	tabKey,
		// 	formOption,
		// 	activeRowKey,
		// 	context?.currentTenantKey,
		// 	savedValues,
		// 	initialForm
		// )
		// 	.then((updatedItem: any) => {
		// 		Emitter.emit("alert", {
		// 			type: "success",
		// 			message: "Tag lookup item has been successfully updated.",
		// 			description: "",
		// 			top: true,
		// 			closeable: false,
		// 			timeout: 3000,
		// 		});
		// 		setActiveRecord(updatedItem);
		// 		setSideContainerOpen(true);
		// 		resetForm(updatedItem);
		// 		// setFilteredExtraData(null);
		// 		setUpdated(!updated);
		// 	})
		// 	.catch((error: any) => {
		// 		// console.log(error);
		// 		Emitter.emit("alert", {
		// 			type: "error",
		// 			message: "Failed to update. Please try again.",
		// 			description: "",
		// 			top: true,
		// 			closeable: false,
		// 			timeout: 3000,
		// 		});
		// 	})
		// 	.finally(() => {});
		setModalVisible(false);
	};

	const handleModalCancel = () => {
		setModalVisible(false);
		handleModalClose();
	};

	const handleFormSave = (values: any) => {
		// For each values in the form, trim the leading and trailing spaces
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});
		getCrudaClass("task").then((crudaClass: any) => {
			if (activeRowKey) {
				//Set undefined to empty string
				Object.keys(values).forEach((element: any) => {
					if (values[element] == undefined) {
						if (element == "subsidiaries") {
							values[element] = [];
						} else {
							values[element] = null;
						}
					}
				});
				const form = crudaClass.getFormList("task");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});
				crudaClass
					.updateLookupItem(
						"task",
						props?.option,
						activeRowKey,
						context?.currentTenantKey,
						values,
						initialForm,
						{
							templateData: props?.otherData,
							project_code: context?.currentProject?.project_code,
							well_project_code: context?.currentWell?.well_project_code,
							phase_id: phaseID,
						},
						props.user,
						formList
					)
					.then((updatedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Task has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, activeRowKey);
						// setActiveRecord(updatedItem);
						resetForm(values, true);
						setUpdated(!updated);
						handleFormUpdate(updatedItem);
						// Update the updatedItem into recordData.task_data
						const newTaskData = record?.task_data?.map((item: any) => {
							if (item._id === updatedItem._id) {
								return updatedItem;
							}
							return item;
						});
						const newRecord = { ...record, task_data: newTaskData };
						Emitter.emit("resetForm", newRecord);
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Task already exists, please ensure the Task ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					})
					.finally(() => {});
			} else {
				// add sequence_id to new data by adding the length of phaseTasks
				values.sequence_id = phaseTasks?.length + 1;

				crudaClass
					.addLookupItem(
						"task",
						props?.option,
						values,
						{
							templateData: props?.otherData,
							project_code: context?.currentProject?.project_code,
							well_project_code: context?.currentWell?.well_project_code,
							phase_id: phaseID,
						},
						context?.currentTenantKey,
						null,
						props.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Task has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "new");
						handleFormUpdate(addedItem);
						resetForm(values, true);
						setUpdated(!updated);
						relatedTask.push(addedItem);

						// reset form in crudTabTableModal
						const recordData = { ...record };
						const task_data = recordData?.task_data || [];
						task_data.push(addedItem);
						recordData.task_data = task_data;
						Emitter.emit("resetForm", recordData);
					})
					.catch((error: any) => {
						console.log(error);
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Task already exists, please ensure the Task ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					})
					.finally(() => {
						Emitter.emit("loading", false);
					});
			}
		});
	};

	const handleFormDelete = () => {
		context?.handleFormUpdating(false, activeRowKey);
		getCrudaClass("task").then((crudaClass: any) => {
			crudaClass
				.deleteLookupItem(
					"task",
					props?.option,
					activeRowKey,
					context?.currentTenantKey,
					{
						project_code: context?.currentProject?.project_code,
						well_project_code: context?.currentWell?.well_project_code,
					},
					{
						templateData: props?.otherData,
						phase_id: phaseID,
					},
					props.user
				)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Task has been successfully deleted.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.finally(() => {
					handleModalClose();
					handleFormUpdate();
				});
		});
	};

	return (
		<Modal
			closable={false}
			width={"80%"}
			title={
				taskRecord?.closed === true ? (
					<span style={{ display: "flex", gap: "5px", fontSize: "14px" }}>
						<div>Task configuration</div>
						<div style={{ color: "rgba(255, 255, 255, 0.45)" }}>
							Further edits on this task are not allowed as it is closed.
						</div>
					</span>
				) : (
					"Task configuration"
				)
			}
			open={open}
			classNames={{ header: "generic-header", footer: "generic-footer" }}
			centered
			destroyOnClose
			footer={() => {
				return (
					<div
						className="generic-footer"
						style={{ height: "auto", padding: "0", width: "100%" }}
					>
						{/* {taskRecord?.closed !== true && ( */}
						<Popconfirm
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							placement="topLeft"
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										Permanently delete this task?
										<br />
										This action cannot be undone.
									</>
								</div>
							}
							open={deletePopover}
							//description="Permanently delete this item? This action cannot be undone."
							onConfirm={handleFormDelete}
							overlayClassName="popconfirm-danger"
							onCancel={() => {
								// Handle cancel action if needed
								setDeletePopover(false);
							}}
							okText="Delete"
							okType="danger"
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							cancelText={<span>Cancel</span>}
							cancelButtonProps={{ ghost: true }}
						>
							<Tooltip
								title={
									taskRecord?.closed === true
										? "This task is not allowed to be deleted as it is closed."
										: ""
								}
							>
								<Button
									disabled={
										!activeRowKey ||
										taskRecord?.disabled ||
										taskRecord?.closed === true
									}
									className="ant-btn-secondary"
									style={{ marginRight: "auto" }}
									danger
									onClick={() => {
										setDeletePopover(true);
									}}
								>
									Delete
								</Button>
							</Tooltip>
						</Popconfirm>
						{/* )} */}

						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										You have unsaved changes. Discard them or continue editing?
									</>
								</div>
							}
							open={cancelPopover}
							placement="topRight"
							cancelButtonProps={{ ghost: true }}
							cancelText="Discard changes"
							onCancel={() => {
								setCancelPopover(false);
								// handleFormCancel();
								setTimeout(() => {
									handleFormCancel();
								}, 300);
							}}
							okText="Continue editing"
							okType="default"
							onConfirm={() => {
								setCancelPopover(false);
							}}
						>
							<Button
								className="ant-btn-secondary"
								onClick={() => {
									if (formReset) {
										handleFormCancel();
									} else {
										setCancelPopover(true);
									}
								}}
							>
								{/* {taskRecord?.status !== "Closed" ? <>Cancel</> : <>Close</>} */}
								Cancel
							</Button>
						</Popconfirm>

						{/* {taskRecord?.closed !== true && ( */}
						<Popconfirm
							icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
							style={{ marginRight: "auto", background: "#393939" }}
							overlayInnerStyle={{ background: "#393939" }}
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									<>
										This will update tag in all groups. Do you wish to proceed?
									</>
								</div>
							}
							placement="topRight"
							open={modalVisible}
							cancelButtonProps={{ ghost: true }}
							cancelText="Cancel"
							onCancel={handleModalCancel}
							onConfirm={handleModalOk}
							okText="Proceed"
							okType="default"
						>
							<FormButtonSave
								activeKey={activeRowKey}
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
								setFormReset={setFormReset}
							></FormButtonSave>
						</Popconfirm>
						{/* )} */}
					</div>
				);
			}}
		>
			<div
				style={{
					overflow: "auto",
					height: "50vh",
					display: "flex",
					flexDirection: "row",
					gap: "30px",
					padding: "15px",
					flexWrap: "wrap",
				}}
			>
				<div style={{ width: "40%" }}>
					<CustomForm
						setFormReset={setFormReset}
						formRef={formRef}
						tabKey={"task"}
						formOption={props?.option}
						activeRowKey={activeRowKey}
						activeRecord={taskRecord}
						dataOptions={{ ...extraData, ...record }}
						disabledKey={"disabled"}
						disabledTooltipMessage={
							taskRecord?.closed === true ? (
								<span>
									Further edits on this task are not allowed as it is closed.
								</span>
							) : (
								<span>
									Further edits on this task are not allowed as it is excluded.
								</span>
							)
						}
						specialDisabledTooltipMessage={
							taskRecord?.closed === true ? (
								<span>
									Further edits on this task are not allowed as it is closed.
								</span>
							) : (
								<span>
									Further edits on this task are not allowed as it is excluded
									automatically due to excluded phase.
									<br />
									<br />
									To include this task, please include the phase first.
								</span>
							)
						}
						readOnly={taskRecord?.closed === true}
					/>
				</div>
				<div
					style={{
						flex: "1",
						height: "100%",
						display: "flex",
						maxWidth: "100%",
						overflow: "hidden",
						flexDirection: "column",
					}}
				>
					{" "}
					<div
						className="generic-table-header"
						style={{ display: "flex", flexDirection: "row" }}
					>
						<span
							style={{
								marginRight: "auto",
								minHeight: "32px",
								display: "flex",
								alignItems: "center",
							}}
						>
							Deliverable directory
						</span>

						{!activeRowKey ? (
							<Tooltip
								title="Will be enabled after saving task details."
								placement="bottom"
							>
								<Button
									onClick={() => {
										setDeliverableModalOpen(true);
									}}
									style={{}}
									disabled={true}
								>
									+ Add
								</Button>
							</Tooltip>
						) : (
							<Tooltip
								title={
									taskRecord.disabled
										? "Further edits on this task are not allowed as it is excluded."
										: ""
								}
								placement="bottom"
							>
								<Button
									onClick={() => {
										setDeliverableModalOpen(true);
									}}
									style={{}}
									disabled={taskRecord.disabled || taskRecord?.closed === true}
								>
									+ Add
								</Button>
							</Tooltip>
						)}
					</div>
					<div
						id="sub-table-container"
						style={{ flex: 1, maxHeight: "100%", overflow: "hidden" }}
					>
						<Table
							rowClassName={(record: any) => {
								let className = "";
								if (record.disabled || taskRecord.disabled) {
									className += "antd-table-row-disabled ";
								}
								return className;
							}}
							pagination={{
								defaultPageSize: 20,
								pageSizeOptions: [20, 50, 100],
								showSizeChanger: true,
								position: ["bottomCenter"],
							}}
							columns={tableColumns}
							expandable={{
								expandedRowKeys: expandedRowKeys,
								onExpand: (expand: any, record: any) => {
									let keys = [...expandedRowKeys];
									if (expand) {
										keys.push(record.uuid);
									} else {
										let index = keys.findIndex(
											(item: any) => item == record.uuid
										);
										if (index != -1) {
											keys.splice(index, 1);
										}
									}
									setExpandRowKey(keys);
								},
							}}
							scroll={tableHeight && { y: tableHeight, x: "0px" }}
							dataSource={filteredDataTable || dataTable}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default TaskConfigurationModal;
