import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";

export const fileUpload = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/file_upload`, data, AuthHeader)
			.then((res: any) => {
				resolve(res);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const fileDownload = (
	file: any,
	azureFilePath: string,
	tenant: string
) => {
	return new Promise((resolve: any, reject: any) => {
		axios
			.post(
				`${url}/file_download`,
				{ file, azureFilePath, tenant },
				{
					...AuthHeader,
					// responseType: "blob",
				}
			)
			.then((response: any) => {
				console.log(response.data);
				// const url = window.URL.createObjectURL(response.data);
				const a = document.createElement("a");
				a.href = response.data;
				a.download = file.name;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
				resolve();
			})
			.catch((e: any) => {
				console.log(e);
				reject(e);
			});
	});
};

export const fileRemove = (file: any, azureFilePath: any, tenant: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${url}/file_remove`, { file, azureFilePath, tenant }, AuthHeader)
			.then((res: any) => {
				resolve(res);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
